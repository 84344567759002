import React, { useState, useEffect } from "react";
import "./BlogSection.css";
import { NavLink } from "react-router-dom";
import { client, urlFor } from "../../../../configs/sanity";
import { fetchPosts } from "../../../../configs/utils/data";
import Title from "../../../../vendor/template/front/_layouts/Title";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

const BlogSection = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchPosts).then((data) => {
        setPosts(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="blogsection">
      <div className="main p-5">
        <Title
          title={"Notre blog"}
          subtitle={"Lisez nos derniers articles de blog sur le transfert d'argent et plus encore !"}
        />
        <div className="posts bg-gray">
          {posts?.map((post) => (
            <SingleReviewCard key={post?._id} post={post}/>
          ))}
        </div>
        <div className="view-all">
          <NavLink className='btn btn-block btn-outline-dark' to={"/blog"}>
            Afficher tous les messages
            <i className="fa fa-arrow-right mx-2"></i>
          </NavLink>
         
        </div>
      </div>
    </div>
  );
};



function SingleReviewCard({post}) {

  return (
    <Card sx={{ maxWidth: 345, margin: '10px'  }}>
      <CardHeader
        
        title={`${post?.title.slice(0, 70)}...`}
      />
      <CardMedia
        component="img"
        height="194"
        image={urlFor(post?.coverImage)}
        alt={post?.title}
      />
      <CardContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {post?.description.slice(0, 250)}...
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <NavLink className='btn btn-sm btn-outline-dark' to={`/blog/${post?.slug.current}`}>
          Lire plus
          <i className="fa fa-arrow-right mx-2"></i>

        </NavLink>
      </CardActions>
    </Card>
  );
}


export default BlogSection;
