import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const banksConfiguration = {
  name: 'banks',
  title: 'Banques',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'lg',
    },
  },
  actions : [
    {
      name: 'edit',
      title: 'Modifier devise',
      color: 'success',
      icon: 'pen',
    },
    {
      name: 'delete',
      title: 'Supprimer devise',
      color: 'danger',
      icon: 'trash',
    },
    
  ]
};

export default BaseConfiguration (banksConfiguration);
