import DefaultController from './default.controller';

export default class RecipientsController extends DefaultController {
  formatToObject = [
    {field: 'bank', source: 'banks'},
    {field: 'currency', source: 'currencies'},
  ];

  constructor () {
    super ({
      path: 'recipients',
    });
  }
}
