import { Fragment } from "react"
import { capitalizeFirstLetter } from "../bootstrap/Helpers/utils";
import NotfoundComponent from "../app-features/commons/Notfound";
import routeGuards from '../bootstrap/Helpers/route-guards';
import { Navigate, Outlet, Route } from "react-router-dom";
import { useSelector } from "react-redux";



function findComponentByRouteName (name, target = null) {
  if (name === '') {
    name = 'home';
  }

  if (!name) {
    throw new Error ('No name provided');
  }
  let defaultTarget = target || 'commons';
  const componentName = capitalizeFirstLetter (name).trim ();
  try{
      const component = require (`../app-features/${defaultTarget}/${componentName}`)
      .default;
      return component;
  }catch(exception){
    return NotfoundComponent; 
  }
}


function ProtectionCheck({protections,children}){
  const user =  useSelector(state => state.auth);

  let redirectionPath = routeGuards (protections, user);
  if(redirectionPath){
    return <Navigate to={"/"+redirectionPath} replace />;
  }
  return children ? children : <Outlet />;
}

const routeBuilder = (routeProperty = {
  routeInformation : null,user : null, prefix : '', protections : []
  }) => {
  const routeInformation = routeProperty?.item;
  if(!routeInformation){
    return null;
  }
  const prefix = routeProperty?.pathPrefix ;
  const target = routeProperty?.componentTarget;
  let protections = routeProperty?.pathProtections;

  let path = routeInformation?.path
    ? routeInformation?.path?.toLowerCase ()?.trim ()
    : routeInformation?.name?.toLowerCase ()?.trim ();

  if(prefix && prefix !== ''){
    path = prefix + '/' + path; 
  } 

  let component = routeInformation.component
  ? findComponentByRouteName (routeInformation?.component, target)
  : findComponentByRouteName (routeInformation?.name, target);

  if (!component) {
      component = NotfoundComponent;
  }


  if(routeInformation?.protections){
    protections = protections.concat(routeInformation?.protections);
  }

  const RenderComponent = component;

  if(protections?.length > 0){
      return  <Route 
                path={'/' + path}
                element={
                  <ProtectionCheck protections={protections}  />
                }
              >
                <Route
                  key={routeInformation?.name?.toLowerCase ()?.trim ()}
                  path={'/' + path}
                  element={<RenderComponent />}
                />
              </Route>
  }

  return (
    <Route
      key={routeInformation?.name?.toLowerCase ()?.trim ()}
      path={'/' + path}
      element={<RenderComponent />}
    />
  );
} 


export default function RoutesBuilder (props) {
  return  <Fragment>
              {
                props.routes.map (
                  item => 
                    <Fragment key={item.name}>
                    {
                      routeBuilder({
                        item,
                        pathPrefix : props.prefix, 
                        pathProtections : props.protections,
                        componentTarget : props.target
                      })
                    }
                  </Fragment>
                )
              }
          </Fragment>
}