import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/images/logo.png';
import './login.css';
import {useState} from 'react';
import Button from '@mui/material/Button';
import googleIcon from './google-icon.png';


import {
  IconButton,
  InputAdornment, 
  TextField,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';


const defaultTheme = createTheme ();


export default function LoginRender (props) {
  const [loginFormValues,setLoginFormValues] = useState({});

  const [showPassword, setShowPassword] = useState (false);

  const handleClickShowPassword = () =>
    setShowPassword (showPassword => !showPassword);

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  const handleFormChanges = (e,name = null, value = null) => {
    setLoginFormValues({
      ...loginFormValues,
      [name || e.target.name] : value || e.target.value
    })
  }

  const onSignInWithGoogle = (e) => {
    e.preventDefault();
    props.frameConfigs.onSubmitForm(e,{
      signInType: {value : 'withGoogle'}
    })
  }

 /* const onSignInWithApple = (e) => {
    e.preventDefault();
    signInWithPopup(appAuthGoogleProvider)
  }*/

  return (
    <div className='login-body bg-light'>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginY: 0,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <div className="form-container col-12 col-md-8 col-xl-8 col-lg-8">
           
            <Box
              component="form"
              onSubmit={(e) => {props.frameConfigs.onSubmitForm(e,{
                signInType: {value : 'withCredentials'}
              })}}
              noValidate
              sx={{my: 2}}
            >
              <div className="form-icon">
                <img
                  className="rounded-circle logo"
                  alt="user-avatar"
                  src={logo}
                />
              </div>
              
              <div className="form-card my-2 col-12">
                <Typography component="h4" sx={{color: 'black',textAlign:'center'}} variant="h5">
                  ESPACE CONNEXION
                </Typography>
              
                <h3 className='mt-4'>SE CONNECTER AVEC</h3>
                <div className="login_option d-fex justify-content-center">
                  <div className="option">
                    <NavLink onClick={(e) => {onSignInWithGoogle(e)}}>
                      <img src={googleIcon} alt="Google" />
                      <span>Google</span>
                    </NavLink>
                  </div>
                  
                </div>
                <p className="separator">
                  <span>OU</span>
                </p>

                <TextField
                  error={props?.dialogInformation?.dialogContent?.fieldsData?.username?.errors ? true : false}
                  sx={{ my:2 }}
                  required
                  label="Email ou nom d'utilisateur"
                  fullWidth
                  name="username"
                  onChange={handleFormChanges}
                  onBlur={props.frameConfigs?.handleFormField}
                  autoComplete="false"
                  value={loginFormValues?.username|| ""}
                  helperText={props?.dialogInformation?.dialogContent?.fieldsData?.username?.errors || null}
                />

                 <TextField
                    error={props?.dialogInformation?.dialogContent?.fieldsData?.password?.errors ? true : false}    
                    required
                    autoComplete="false"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    onChange={handleFormChanges}
                    onBlur={props.frameConfigs?.handleFormField}
                    value={loginFormValues?.password|| ""}
                    
                    helperText={props?.dialogInformation?.dialogContent?.fieldsData?.password?.errors || null}
                    InputProps={{
                      endAdornment:   <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>,
                    }}
                    fullWidth
                    label="Mot de passe"
                  />
              

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    my: 2,
                    backgroundColor: '#000039',
                    borderColor: '#000039',
                    color: '#fff',
                    p: 2,
                  }}
                >
                  CONNEXION
                </Button>

                <p className="sign_up">
                          Vous n'avez pas de compte ? 
                          <NavLink to="/register"> S'inscrire</NavLink>
                 </p>
              </div>

            </Box>

          </div>

        </Box>
      </Container>
    </ThemeProvider>
    </div>
  );
}
