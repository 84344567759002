import DefaultController from './default.controller';

export default class ChangetaxesController extends DefaultController {
  formatToObject = [
    {field: 'currency1', source: 'currencies'},
    {field: 'currency2', source: 'currencies'},
    {field: 'fee', source: 'fees'},
  ];

  constructor () {
    super ({
      path: 'change-taxes',
    });
  }
}
