import React from 'react';
import HeroSection from '../sections/HeroSection/HeroSection';
import AboutSection from '../sections/AboutSection/AboutSection';
import Review from '../sections/Review/Review';
import Values from '../sections/Values/Values';
import BlogSection from '../sections/BlogSection/BlogSection';

const Home = (props) => {
    return (
      <div className="home">
        <HeroSection options={props.frameConfigs.options} />
        <AboutSection />
        <Review />
        <Values />
        <BlogSection />
      </div>
    );
  };
  
  export default Home;
  