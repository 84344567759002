import React, { useEffect, useState } from "react";
import '../../../../../assets/site/css/styles.css';

import { NavLink } from "react-router-dom";
import { BiLogoFacebookSquare } from "react-icons/bi";
import Logo from "../../../../../assets/site/img/well.png";


const Footer = () => {
  const [year, setYear] = useState("");
  useEffect(() => {
    const year = new Date().getFullYear();
    setYear(year);
  }, []);

  return (
    <div className="footer">
      <div className="container">
        <div className="top-footer">
          <div className="Logo-icon">
            <img src={Logo} alt="well transfer" />
          </div>
          <div className="address">
            {/* <h3>Adresse physique</h3> */}
            {/* <h3>Coordonnées</h3> */}
            <p>Welltransfer@outlook.com</p>
            <p>+7 (931) 985-47-06</p>
            <div className="facebook">
              <span>
                <BiLogoFacebookSquare />
              </span>
              <a
                href="https://www.facebook.com/WellTransfer/"
                target="_blank"
                rel="noreferrer"
              >
                Well Transfer
              </a>
            </div>
            <p>Saint-Pétersbourg, Russie</p>
          </div>
          <div className="address">
            <h3>Well Transfer</h3>
            <p>
              <NavLink to={"/aboutus"}>À propos de nous</NavLink>
            </p>
            <p>
              <NavLink to={"/contactus"}>Contactez-nous</NavLink>
            </p>
            <p>
              <NavLink to={"/blog"}>Blog</NavLink>
            </p>
          </div>
        </div>
        <p className="copyright">© {year} Well Transfer. Tous droits réservés</p>
      </div>
    </div>
  );
};

export default Footer;
