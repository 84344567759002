import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { InputTypePhone } from "../../../../../../../vendor/builders/Form/Input";

export default function PersonalInfo(props){
    const [personalInfo,setPersonalInfo] = useState(props.oldValue || {});
    const globalInfo = props.globalInfo || {};
    
    const changeText = (e, value = null, name = null) => {
        e?.preventDefault();
        setPersonalInfo({
            ...personalInfo,
            [name || e.target.name] : value || e.target.value  
        });
    }

    const setInfo = (info) => {
        props.setFormInfo({...globalInfo,personalInfo : personalInfo});
    }

    return <Fragment>
            <div className='my-2'>
                <h5>INFORMATION SUR CLIENT & BENEFICIAIRE</h5> 
            </div>
            <div className="amount row">
                    <div className="col-4  my-3">
                    <Autocomplete
                        options={props.clients || []}
                        sx={{ width: '100%' }}
                        renderInput={(params) => <TextField error={props?.errors?.find(error => error.name === "sender")?.error}
                        helperText={props?.errors?.find(error => error.name === "sender")?.error || null} fullWidth {...params} label="Envoyeur" />}
                        name = "sender"
                        onChange={(e,value) => {changeText(e,value,"sender")}}
                        value={personalInfo.sender}
                        error={props?.errors?.find(error => error.name === "sender")?.error}
                        helperText={props?.errors?.find(error => error.name === "sender")?.error || null}
                    />
                </div>
                <div className="col-4  my-3">
                    <TextField
                        fullWidth
                        label="Nom et prenom(s) du beneficiaire"
                        onChange={changeText}
                        name = "recipientName"
                        value={personalInfo.recipientName}
                        onBlur={(e) =>  setInfo(personalInfo)}
                        error={props?.errors?.find(error => error.name === "recipientName")?.error}
                        helperText={props?.errors?.find(error => error.name === "recipientName")?.error || null}
                    />
                </div>
                <div className="col-4  my-2">
                    <InputTypePhone
                        error={props?.errors?.find(error => error.name === "recipientContact")?.error}
                        value={personalInfo.recipientContact}
                        changeValue = {(e,value = null,name = null) => {
                                changeText(e,value,name)
                                personalInfo.recipientContact = value;
                                setInfo(personalInfo)
                            }
                        }
                        fieldInformation = {
                          {
                            label : 'Contact du beneficiaire',
                            name: 'recipientContact'
                          }
                        }
                      />
                </div>
            </div>
        </Fragment>

}