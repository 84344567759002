import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class TransactionsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'transactions',
      path: 'transactions',
    });
  }

  async transactionByClient (id) {
    return await this.controller.transactionByClient (id);
  }

  async valid (id) {
    return await this.controller.valid(id);
  }

  async cancel (id) {
    return await this.controller.cancel(id);
  }
}
