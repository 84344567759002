import { Fragment } from 'react';
import './profile.css';
import moment from 'moment';
import profile from '../../../assets/images/profile.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProfileRender = props => {
  
  let {transactions} = {totalClient : 0, transactions : []};

  if(props.renderFrameConfigs.fetchData){
    //totalClient = props.renderFrameConfigs.fetchData.totalClient;
    transactions = props.renderFrameConfigs.fetchData.transactions;
  }

  let totalTransactionsAmount =  0;

  if(transactions){
    transactions?.forEach(transaction => {
      totalTransactionsAmount += transaction.total;
    });
  }

  
  return <Fragment>
             <div className="client-content">
                <div className="p-2 text-center text-white client-title">
                    <h4>PROFILE CLIENT</h4>
                </div>
                <div className="card-body p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={profile} alt="logo" className="rounded" width="100" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">{'KOUADIO MOLOU'}</h5>
                            <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">N° de téléphone</span>
                                    <span className="number1">
                                    {'0757044183'} 
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Adresse</span>
                                    <span className="number1">
                                    {'ANYAMA PK18'} 
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Email</span>
                                    <span className="number1">
                                      moloundoua@gmail.com 
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="number1">
                                      <span className="articles"></span>
                                      <button className='btn btn-sm btn-primary'>
                                        <span className='mx-2'>Editer profile</span>
                                        <FontAwesomeIcon icon={'pen'}/> 
                                      </button>
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                   
                </div>

                <div className='row p-3'>
                  <div className='col-xl-4 col-md-4 col-lg-4 col-12'>
                  
                    <div className="col-12">
                      <div className="card">
                        <div className="card-content">
                          <div className="card-body">
                            <div className="media d-flex">
                              <div className="align-self-center">
                                <i className="icon-speech warning font-large-2 float-left"></i>
                              </div>
                              <div className="media-body text-right">
                                <h3>{transactions ? transactions?.length?.toLocaleString('fr-FR') : 0}</h3>
                                <span>POINTS BONUS</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="card">
                        <div className="card-content">
                          <div className="card-body">
                            <div className="media d-flex">
                              <div className="align-self-center">
                                <i className="icon-graph success font-large-2 float-left"></i>
                              </div>
                              <div className="media-body text-right">
                                <h3>{totalTransactionsAmount ? totalTransactionsAmount?.toLocaleString('fr-FR') : 0}</h3>
                                <span>FILLEULE(s)</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-8 col-md-8 col-lg-8 col-12'>
                      <div className='title text-white p-4'>HISTORIQUE DES TRANSACTIONS</div>
                      <div className="card-body">
                        <div className='table table-responsive'>
                          <table className='table table-responsive table-stripped table-borderd'>
                              <thead>
                                  <tr>
                                    <th>Date & Heure</th>
                                    <th>Envoyeur</th>
                                    <th>Receveur</th>
                                    <th>TOTAL</th>
                                  </tr>
                              </thead>
                              <tbody>
                                {
                                  transactions.length > 0 ? transactions.reverse().splice(10).map(
                                    transaction => <tr key={transaction.id}>
                                                      <td>{moment(transaction.dateTransaction).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                      <td>{transaction.senderName}</td>
                                                      <td>{transaction.recipientName}</td>
                                                      <td>{transaction.total.toLocaleString('fr-FR')}</td>
                                                  </tr> 
                                  ) : <tr>
                                        <td className='text-center text-danger' colSpan={4}>Aucune transaction :)</td>
                                  </tr>
                                }

                              </tbody>
                          </table>
                        </div>
                      </div>
                  </div>
                </div>  
            </div>
            
            
        </Fragment>;
};

export default ProfileRender;
