/**
 * Login action for user 
 * @param {*} user 
 * @returns //information of connected user
 */
function login (user) {
  return {
    type: 'login successful',
    payload: user,
  };
}
/**
 * Logout action
 * @returns 
 */
function logout () {
  return {
    type: 'logout successful',
    payload: null,
  };
}


/**
 * Register action for user 
 * @param {*} user 
 * @returns //information of connected user
 */
function register (user) {
  return {
    type: 'register successful',
    payload: user,
  };
}

const userAction = {login, logout, register};

export default userAction;
