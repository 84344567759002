import DefaultController from './default.controller';

export default class DashboardController extends DefaultController {

  async readAllWithSpecification (specifications, path = null) {
    const response = await this.readFirebase (path || this.path);
    // const formatData = this.completeFormat(dataFormatter.ListFormatterResponse (response?.data));
    return {
      ...response,
    };
  }

  async dashboardData(){
    let totalClient = 0;
    let transactions = [];
   


    return {
      success : true,
      message : null,
      data : {
        totalClient,
        transactions
      }
    }
  }
  
}
