import React, { Fragment } from "react";
import EditProfileRender from "./render";
import ClientsService from "../../../../../external-communication/services/clients.service";

export default function FormProfile(props){
    return <EditProfile {...props}/>
}

class EditProfile extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            data : null
        }
        this.update = this.update.bind(this);
    }

    async componentDidMount(){
        const profile = await new ClientsService().one(this.props?.profileInformation?.id);
        
        if(profile.success){
            await this.setState({data : profile.data})
        }
    }

    async update(e,formValue){
        let newObject = {};
        for(let data in formValue){
            newObject = {
                ...newObject,
                [data]: {value : formValue[data]}
            }
        }

        const profileUpdateQuery = await new ClientsService().update(newObject,this.state.data.id);
        if(profileUpdateQuery.success){

        }
    }

    render() {
      return (
        <Fragment>
          <EditProfileRender update={this.update} {...this.props}/>
        </Fragment>
      )
    }
}