import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class PromotionsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'promotions',
      path: 'promotions',
    });
  }

  async valid (id) {
    return await this.controller.active(id);
  }

  async cancel (id) {
    return await this.controller.disable(id);
  }
}
