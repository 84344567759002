import DefaultController from './default.controller';

export default class PaymentwaysController extends DefaultController {
  formatToObject = [{field: 'recipient', source: 'recipients'}];

  constructor () {
    super ({
      path: 'payment-ways',
    });
  }
}
