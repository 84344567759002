import BaseBackComponent from './../base-back-component';

export default class FeesComponent extends BaseBackComponent {
  name = 'Fees';

  options = [
    {
      title: 'currencies',
      keys: ['name'],
    },
  ];

  state = {
    ...this.state,
    searchOptions: {
      ...this.state.searchOptions,
    },
  };

  componentDidMount () {
    this.initDataLoading ();
    this.loadOptions ();
  }

  otherSingleDataInit (data) {
    const fees = data?.feesList?.value.map(
      fee => {
        return {
          bankFee : {value : fee.bankFee},
          calculWay : {value : fee.calculWay},
          smallAmount : {value : fee.smallAmount},    
          maxAmount : {value : fee.maxAmount},    
        }
      }
    ) 

    return {
      ...data,
      feesList : {value : fees},
    }
  }
}
