import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const transactionsConfiguration = {
  name: 'transactions',
  title: 'Transaction(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'dateTransaction',
      title: 'Date',
      type: 'datetime',
    },
    {
      name: 'transactionNumber',
      title: 'N° de transaction',
    },
    {
      name: 'taxe',
      title: 'Devises',
      htmlElement: 'select',
      type: 'objectFetcher',
      col: '6',
      options: [],
      typeValueSent : 'object',
      dataServiceSource: 'changetaxes',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'changetaxes',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'sentAmount',
      title: 'Montant envoyé',
      type: 'money',
      col: '6',
    },
    {
      name: 'sender',
      title: 'Envoyeur',
      col: '6',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      typeValueSent : 'object',
      dataServiceSource: 'clients',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'clients',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'paymentWay',
      title: 'Moyen de paiement',
      col: '6',
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      typeValueSent : 'object',
      dataServiceSource: 'paymentways',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'paymentways',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'recipientName',
      title: 'Destinataire',
      col: '6',
    },
    {
      name: 'recipientContact',
      title: 'Destinataire',
      col: '6',
    },
    {
      name: 'fee',
      type: 'money',
      title: 'Frais',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'receivedAmount',
      type: 'money',
      title: 'Montant recu',
      htmlElement: 'disableInput',
      col: '6',
      editableDisable: true,
    },
    {
      name: 'total',
      type: 'number',
      htmlElement: 'disableInput',
      col: '12',
      editableDisable: true,

    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: 'orange', text: 'En attente', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Validee'},
        2: {badgeColor: '#DC143C', text: 'Annulee'},
      },
      type: 'state-multiple',
    },
  ],
  excludeToForms: ['dateTransaction',"transactionNumber","paymentWay"],
  excludeToHeaders: ['taxe','fee','receivedAmount','sentAmount','recipientContact',"total"],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
  formConfig: {
    addFormTitle: 'EFFECTUER UNE TRANSACTION',
    editFormTitle: "MODIFIER INFORMATION D'UNE TRANSACTION",
    
  },
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'primary',
      icon: 'eye',
    },
    {
      name: 'valid',
      title: 'Active',
      color: 'info',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Annuler',
      color: 'danger',
      icon: 'times',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],

};

export default BaseConfiguration (transactionsConfiguration);
