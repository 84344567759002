import { Autocomplete, Box, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import mainTheme from "../../../../../../../configs/mainTheme";

export default function AmountSentFormInfo(props){
    const [amountSentInfo,setAmountSentInfo] = useState(props.oldValue || {});
    const globalInfo = props.globalInfo || {};
    const [currencies,setCurrencies] = useState([]); 
    
    const [sendCurrency,setSendCurrency] = useState(currencies[0]);
  
    const [receivingCurrency,setReceivingCurrency] = useState(currencies[1]);

    useEffect(
      () => {
        const getCurrencies = () => {
          setCurrencies(props.currencies || [])
        }
        getCurrencies();
        setSendCurrency(currencies[0]);
        setReceivingCurrency(currencies[1]);
      },[currencies,props]
    );
  
    const handleSendCurrency = (value) => {
      setSendCurrency(value);
      handleCurrentParam(value,receivingCurrency);
    }
  
    const handleReceivingCurrency = (value) => {
      setReceivingCurrency(value);
      handleCurrentParam(sendCurrency,value);
    }

    const handleCurrentParam = (sendParams,receivingParams) => {
      const foundTaxesParam = taxes.find(taxe => {
        return taxe.originalObject.currency1.id === sendParams.value && taxe.originalObject.currency2.id === receivingParams.value;
      });
      handleOptionChange(foundTaxesParam)
    }

    const changeText = (name,value) => {
        setAmountSentInfo({
            ...amountSentInfo,
            [name] : value  
        });
    }

    const setInfo = (info) => {
        props.setFormInfo({...globalInfo,amountSentInfo : amountSentInfo});
    }
  
  const [selectedOption, setSelectedOption] = useState({});
  const [totalfee, setTotalfee] = useState(0);
  const [sendingAmount, setSendingAmount] = useState(0);
  const [receivingAmount, setReceivingAmount] = useState(0);
  const [rate, setRate] = useState(0);
  const [currenciesParams,setCurrenciesParams] = useState(null);

  const taxes = props.taxes || [];
  

  const HandleAmountChange = (e) => {
    setSendingAmount(e.target.value);
    changeText('sentAmount',e.target.value);

    HandleConvert({amount : e.target.value})

  };

  const HandleConvert = async (params) => {
    
    let givenAmount = params?.amount || sendingAmount || 0;  
    
    let feesParams = params?.feeParams || selectedOption?.originalObject;


    setCurrenciesParams(feesParams)
    
    let totalFees = 0;

    let totalReceived = 0;
    
    if(feesParams){


      setRate(feesParams.rate)

      totalReceived =  parseFloat(givenAmount) * parseFloat(feesParams.rate)

      let getFee =  feesParams.fee.feesList?.find(fee => {
        if(fee.smallAmount && !fee.maxAmount){
          return fee.smallAmount <= givenAmount;
        }else if(!fee.smallAmount && fee.maxAmount){
          return fee.maxAmount >= givenAmount;
        }
        return fee.smallAmount <= givenAmount && fee.maxAmount >= givenAmount
      }) 

      
      let firstFee = 0;
      let secondFee = 0;

      if(feesParams.fee.calculWay === "Fixe"){
        firstFee =  parseFloat(feesParams.fee.processFee);
      }else if(feesParams.calculWay === "Pourcentage"){
        firstFee =  parseFloat(feesParams.fee.processFee) * parseFloat(givenAmount) / 100;         
      }
      
      if(getFee){
        if(getFee.calculWay === "Fixe"){
          secondFee =  parseFloat(getFee?.bankFee);
        }else if(getFee.calculWay === "Pourcentage"){
          secondFee =  parseFloat(getFee?.bankFee) * parseFloat(givenAmount) / 100;         
        }
      }

       
      totalFees = firstFee  +  secondFee;
    }

    setReceivingAmount(totalReceived);

    setTotalfee(totalFees);
    changeText('fee',totalFees);
    changeText('receivedAmount',totalReceived);
    
    amountSentInfo.fee = totalFees;
    amountSentInfo.receivedAmount = totalReceived;
    amountSentInfo.total = totalReceived + totalFees;
    amountSentInfo.sentAmount = givenAmount;
    amountSentInfo.taxe = feesParams;

    setInfo(amountSentInfo)
  };

    const handleOptionChange = (params) => {
        changeText('taxe',params);
        setSelectedOption(params);
        setTotalfee(0);
        setReceivingAmount(0);
        setSendingAmount(0);
        HandleConvert({feeParams : params?.originalObject})
    };

    return <Fragment>
              <div className='my-2'>
                <h5>INFORMATION SUR TRANSACTION</h5> 
              </div>

              {
                props?.errors?.find(error => error.name === "taxe")?.error ? 
                <div className="text-center bg-danger p-3">
                  <span className="text-white">
                    { props?.errors?.find(error => error.name === "taxe")?.error}
                  </span>
                </div> : null
              }

              <div className="amount row">
                <div className="col-12">
                  <div className="border border-1 border-black amount-input p-2 my-2">
                    <div className="amount-input-title fw-bold fst-italic">
                      Vous envoyez
                    </div>
                    <div className="amount-input-line row my-3 p-2">
                      <div className="col-3 mt-sm-3">
                        <Autocomplete
                            options={currencies}
                            onChange={(e,value) => {
                              handleSendCurrency(value)}
                            }
                            value={sendCurrency}
                            sx={{ width: '100%' }}
                            renderInput={(params) => <TextField fullWidth {...params} label="Devise" />}
                            renderOption={
                              (props,option) => {
                                return <Fragment key={option.label}>
                                          {
                                            <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                              <img
                                                loading="lazy"
                                                width="50"
                                                height="50"
                                                className="img img-thumbnail"
                                                srcSet={`${option?.imgLabelSrc || mainTheme.appLogo} 2x`}
                                                src={`${option?.imgLabelSrc || mainTheme.appLogo}`}
                                                alt="img"
                                              />
                                              {option.label}
                                            </Box>
                                          }
                                      </Fragment>
                              }
                            }
                          />
                      </div>
                      <div className="col-7 mt-sm-3 ">
                        <TextField 
                            onChange={HandleAmountChange} 
                            fullWidth 
                            label="Montant envoye" 
                            error={props?.errors?.find(error => error.name === "sentAmount")?.error}
                            helperText={props?.errors?.find(error => error.name === "sentAmount")?.error || null}
                        />
                      </div>
                      <div className="col-2 p-2 mt-sm-3 bg-light text-center fw-bold">
                        <span className="">
                            <img
                              loading="lazy"
                              width="40"
                              height="40"
                              className="img img-thumbnail mx-1"
                              srcSet={`${sendCurrency?.imgLabelSrc || mainTheme.appLogo} 2x`}
                              src={`${sendCurrency?.imgLabelSrc || mainTheme.appLogo}`}
                              alt="img"
                            />
                          {sendCurrency?.label}
                        </span>
                      </div>  
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="border border-1 border-black amount-input p-2 my-2">
                    <div className="amount-input-title fw-bold fst-italic">
                      A la reception
                    </div>
                    <div className="amount-input-line row my-3 p-2">
                      <div className="col-12 col-xl-3 col-lg-3 col-md-3 mt-sm-3">
                        <Autocomplete
                            options={currencies}
                            onChange={(e,value) => {handleReceivingCurrency(value)}}
                            sx={{ width: '100%' }}
                            value={receivingCurrency}
                            renderInput={
                              (params) => <TextField fullWidth {...params} label="Devise" />
                            }
                            renderOption={
                              (props,option) => {
                                return <Fragment key={option.label}>
                                          {
                                            <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                              <img
                                                loading="lazy"
                                                width="50"
                                                height="50"
                                                className="img img-thumbnail"
                                                srcSet={`${option?.imgLabelSrc || mainTheme.appLogo} 2x`}
                                                src={`${option?.imgLabelSrc || mainTheme.appLogo}`}
                                                alt="img"
                                              />
                                              {option.label}
                                            </Box>
                                          }
                                      </Fragment>
                              }
                            }
                          />
                      </div>
                      <div className="col-12 col-xl-7 col-lg-7 col-md-7 mt-sm-3">
                        <TextField value={receivingAmount} disabled fullWidth label="Montant recu" />
                      </div>
                      <div className="col-12 col-xl-2 col-lg-2 col-md-2 p-3 bg-light text-center fw-bold mt-sm-3">
                        
                      <span className="mt-2">
                            <img
                              loading="lazy"
                              width="40"
                              height="40"
                              className="img img-thumbnail mx-1"
                              src={`${receivingCurrency?.imgLabelSrc || mainTheme.appLogo}`}
                              srcSet={`${receivingCurrency?.imgLabelSrc || mainTheme.appLogo} 2x`}
                              alt="img"
                            />
                          {receivingCurrency?.label}
                        </span>

                      </div>   
                    </div>
                  </div>
                </div>

                  
              </div>
                <hr />
                <div className="fees row">
                    <div className="col-6">
                        <p className="fw-bold">Frais d'envoi</p>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                        <p className="text-primary fw-bold">
                        {currenciesParams?.currency1?.symbol || "₽"}
                        {totalfee?.toFixed(2)}
                    </p>
                    </div>
                
                </div>
                <hr />
             
                {
                    currenciesParams ?
                <div className="fees row">
                    <div className="col-6">
                        <p className="fw-bold">Taux de change actuel</p>
                    </div>
                    
                    <div className="col-6 d-flex justify-content-end">
                        <p className="text-primary fw-bold">{currenciesParams?.currency1?.symbol || "₽"}1 = {currenciesParams?.currency2?.symbol || "$"}{rate}</p>
                    </div> 
                    
                </div>   : null}      
           </Fragment>

}