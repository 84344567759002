import dataFormatter from '../responses/row-formatter.response';
import DefaultController from './default.controller';
import ParamsController from './params.controller';

export default class ClientsController extends DefaultController {
  constructor () {
    super ({
      path: 'clients',
    });
  }

  paramController = new ParamsController ();


  rules = [
    {title: 'name', rules: ['required']},
    {title: 'tel', rules: ['required','unique:clients:tel']},
    {title: 'email', rules: ['required','unique:clients:email']},
  ];

  async otherTreatCredentials (credentials){
    const sponsor =  credentials.sponsor || await this.getSponsoringInfo(credentials.sponsoringCode);
    
    if(sponsor){
      this.addSponsorPoint(sponsor);
    }

  
    
    const newSaveCred = {
      ...credentials,
      code : await this.generateSponsoringCode(),
      state : 1,
      sponsor
    };
    
    return newSaveCred;
  }

  addSponsorPoint(sponsor){
    const paramController = new ParamsController ();

    const pointNumber  = paramController.getByName('Nombre de point inscription affilie')?.value;
    let cumulatePoint = sponsor.cumulatePoint ? sponsor.cumulatePoint : 0;

    cumulatePoint += pointNumber;
    
    sponsor = {
      ...sponsor,
      cumulatePoint
    }
   
    this.update(sponsor.id,sponsor)
    
  }

  async getSponsoringInfo(code = null){
    if(code){
      return await this.findByCode();
    }
    return null;
  }

  getCurrentClientProfile = async () => {

    const authUser = JSON.parse(localStorage.getItem('user'));
    const clientProfile = await this.format(authUser.user);
    return {
      success : true,
      data :  clientProfile,
      errors : [],
      message : ''
    }
  }

  async format (user) {
    let clientFound = await this.findByEmail (user.email);
    return clientFound;
  }

  async findByEmail (email) {
    const clientsQuery = await this.readAll ();
    return clientsQuery.data.find (client => client.email === email);
  }

  async generateSponsoringCode(){
    let sponsoringPromo = Math.floor(Math.random() * (999999 - 100000) + 100000);
    
    const codeChecking = await this.checkCodeClientExistence(sponsoringPromo);

    if(codeChecking){
       sponsoringPromo = await this.generateSponsoringCode()    
    }

    return sponsoringPromo;
  }

  async checkCodeClientExistence(code){
    const response = await this.readFirebase (
        `${('clients' || this.path)}`
    );

    const list = dataFormatter.ListFormatterResponse(response.data);

    const clientByCodeIndex = list.findIndex(client => client.code === code);

    return clientByCodeIndex !== -1;
  }

  async findByCode(code){
    const response = await this.readFirebase (
        `${('clients' || this.path)}`
    );

    const list = dataFormatter.ListFormatterResponse(response.data);

    const clientByCode = list.findIndex(client => client.code === code);

    return clientByCode;
  }

  async findClientAffiliates(clientId){
    const clientssQuery = await this.readAll ();
    return {success: true, data: clientssQuery.data.filter (client => client?.sponsor?.id === clientId),message : 'succes recuperation affiliees'};
  }
}
