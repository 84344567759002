import DefaultController from './default.controller';

export default class AffiliationsController extends DefaultController {
  formatToObject = [{field: 'client', source: 'clients'}];

  constructor () {
    super ({
      path: 'affiliations',
    });
  }
}
