import React, { useState, useEffect } from "react";
import "./TeamSection.css";
import { client} from "../../../../configs/sanity";

import { fetchSteps } from "../../../../configs/utils/data";
import Title from "../../../../vendor/template/front/_layouts/Title";

const TeamSection = () => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchSteps).then((data) => {
        setSteps(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="teamsection">
      {/* <div className="team-layer"></div> */}
      <div className="main p-5 top-layer">
        <div className="team-title">
          <Title
            subtitle={
              "Etapes pour envoyer de l'argent"
            }
          />
        </div>
        <div className="learn-more">
          <div className="main p-5 my-5">
              <section id="steps">
                
                  <div className="row">
                  {
                    steps?.map((step) => (
                      <div key={step?._id} className="col-12 col-xl-4 col-lg-4 col-md-4">
                          <div className="bg-light position-relative px-3 my-5">
                              <div className="step-number font-weight-bold circle text-white rounded-circle d-flex align-items-center justify-content-center mx-auto position-relative border border-white"
                              >
                                {step?.number}
                              </div>
                              <div className="px-3 text-center pb-3">
                                  <h4>{step?.title}</h4>
                                  <p className="font-weight-light my-3">
                                  {step?.text}
                                  </p>
                              </div>
                          </div>
                      </div>
                     ))
                  } 
                  </div>
              </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
