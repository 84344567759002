import React, { useState } from 'react';
import './style.css'
import PersonalInfo from './_layouts/personal-info';
import AmountSentFormInfo from './_layouts/amount-sent-info';
import AccountInfo from './_layouts/account-info';


export default function TransactionFormRender(props){
    const [formInfo,setFormInfo] = useState({});
    const [formError,setFormError] = useState([]);


    const taxes = props.fields.loadFieldOptions?.find(opt => opt.title === "changetaxes")?.options || []; 
    const paymentWays = props.fields.loadFieldOptions?.find(opt => opt.title === "paymentways")?.options || [];
    const currencies = props.fields.loadFieldOptions?.find(opt => opt.title === "currencies")?.options || [];
    const clients = props.fields.loadFieldOptions?.find(opt => opt.title === "clients")?.options || [];

    //console.log(props.fields.onSubmitForm)
    const [activeStep,setActiveStep] = useState(0);
    
    

    const changeFormValue = info => {
        setFormInfo(info);   
    }

    const changeActiveStep = (number) => {
        setActiveStep(number);
        checkFormValidator(number)
    }

    const goToNext = () => {
        if(activeStep !== 2){
            const errors = checkFormValidator(activeStep);

            if(errors.length <= 0){
                const step  = activeStep + 1;
                changeActiveStep(step);
                setFormError([]);
            }else{
                setFormError(errors);
            }
        }
    }

    const checkFormValidator =  number => {
        let errors = []

        if(number === 0){
            
            if(!formInfo?.personalInfo?.sender || formInfo?.personalInfo?.sender === ""){
                errors.push({name : 'sender',error : "Le champ envoyeur est requis"});
            }

            if(!formInfo?.personalInfo?.recipientName || formInfo?.personalInfo?.recipientName === ""){
                errors.push({name : 'recipientName',error : "Le champ nom beneficiaire est requis"});
            }

            if(!formInfo?.personalInfo?.recipientContact || formInfo?.personalInfo?.recipientContact === ""){
                errors.push({name : 'recipientContact',error : "Le champ contact beneficiaire est requis"});
            }
            
            setFormError(errors);

        }else if(number === 1){
            if(!formInfo?.amountSentInfo?.sentAmount || formInfo?.amountSentInfo?.sentAmount === ""){
                errors.push({name : 'sentAmount',error : "Le montant envoye est requis"});
            }

            if(!formInfo?.amountSentInfo?.taxe || formInfo?.amountSentInfo?.taxe === ""){
                errors.push({name : 'taxe',error : "Verifier les devises d'envoie et reception svp"});
            }
            
            setFormError(errors);
        }
        else if(number === 2){
            if(!formInfo?.accountInfo?.paymentWay || formInfo?.accountInfo?.paymentWay === ""){
                errors.push({name : 'paymentWay',error : "Le moyen de paiement est requis"});
            }

            if(!formInfo?.accountInfo?.clientAccountNumber || formInfo?.accountInfo?.clientAccountNumber === ""){
                errors.push({name : 'clientAccountNumber',error : "Le numero de compte client est requis"});
            }
            
            setFormError(errors);
        }
        return errors;
    }

    const goToPrev = () => {
        if(activeStep !== 0){
            const step  = activeStep - 1;
            changeActiveStep(step);
        }
    }

    const registerTransaction = async (e) => {
        let errors = [];
        
        errors = checkFormValidator(2);

        if(errors.length <= 0){
            
            let data = {
                ...formInfo.personalInfo,
                ...formInfo.amountSentInfo,
                ...formInfo.accountInfo,
            };


            let payload = {};

            for(let line in data){
                payload = {
                    ...payload,
                    [line]: {value : data[line]}
                }
            }

           payload.sender = {value : payload.sender?.value?.originalObject};

           payload.paymentWay = {value : payload.paymentWay?.value?.originalObject};
           
 
          props.fields.onSubmitForm(e,"add",payload)

        }else{
            setFormError(errors);
        }
        
    }

    return <div className='d-flex justify-content-center my-5'>
                <div className="form-group card col-12 p-3">
                    <div className="p-3 bg-light text-center">
                       <h3 className='fst-it fw-bold'> FORMULAIRE DE TRANSACTION</h3>
                    </div>
                    
                    {
                        activeStep === 0 ? <PersonalInfo
                                                oldValue = {formInfo.personalInfo}
                                                setFormInfo = {changeFormValue}
                                                globalInfo = {formInfo}
                                                errors = {formError}
                                                clients={clients}
                                            /> : null
                    }

                    {
                        activeStep === 1 ? <AmountSentFormInfo
                                                oldValue = {formInfo.amountSentInfo}
                                                setFormInfo = {changeFormValue}
                                                taxes={taxes}
                                                currencies={currencies}
                                                globalInfo = {formInfo}
                                                errors = {formError}
                                            /> : null
                    }

                    {
                        activeStep === 2 ? <AccountInfo
                                                oldValue = {formInfo.accountInfo}
                                                setFormInfo = {changeFormValue}
                                                globalInfo = {formInfo}
                                                paymentWays={paymentWays}
                                                errors = {formError}
                                            /> : null
                    }

                    

                    {
                        activeStep !== 2 ? <button onClick={goToNext} className="btn btn-primary p-2">
                                                <h6>SUIVANT</h6> 
                                            </button> : null
                    }

                    {
                        activeStep !== 0 ? <button onClick={goToPrev} className="btn btn-danger p-2 mt-2">
                                                <h6>RETOUR</h6> 
                                            </button> : null
                    }

                    {
                        activeStep === 2 ? <button onClick={registerTransaction} className="btn btn-success p-2 mt-2">
                                                <h6>VALIDER</h6> 
                                            </button>  : null
                    }
                </div>
            </div>


}