import BaseFrontComponent from "../base-front-component";


export default class TransactionFormComponent extends BaseFrontComponent{
    name = 'TransactionForm'
    options = [
        {
          title: 'changetaxes',
          keys: ['name'],
        },
        {
            title: 'paymentways',
            keys: ['name'],
        },
        
        {
            title: 'currencies',
            keys: ['symbol'],
            imgTarget:"flag"
        },
    ];

    state = {
        ...this.state
    }

    componentDidMount(){
        this.loadOptions();
    }
}