import Service from './../../vendor/bases/service';

class ProfileService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'profile',
    });
  }

  async all (params = null) {
    const data = await this.treatQuery (this.query.readAll (params));
    return {
      ...data,
      data : this.formatValue(data.data)
    };
  }
}

export default ProfileService;
