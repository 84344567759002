import React from 'react';
import { Navigate } from 'react-router-dom';

const UnauthenticateComponent = () => {
  return (
    <Navigate to='/login'/>
  );
};

export default UnauthenticateComponent;
