import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const ClientTransactions = props => {
    const transactions = [];
    return    <div className='row'>
                <div className="col-12 my-2">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                            <div className="media d-flex">
                                <div className="align-self-center">
                                <i className="icon-speech warning font-large-2 float-left"></i>
                                </div>
                                <div className="media-body text-right">
                                <h3>0</h3>
                                <span>POINTS BONUS</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
              
                <div className="col-12">
                    <div className="table-responsive">
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                            <TableHead sx={{ "& th": {
                                color: "white",
                                backgroundColor: "black"
                                }}}>
                                <TableRow>
                                    <TableCell sx={{ fontWeight:"bold" }}>Num.</TableCell>
                                    <TableCell sx={{ fontWeight:"bold" }}>Date transaction</TableCell>
                                    <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Montant envoyé</TableCell>
                                    <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Frais d'envoi</TableCell>
                                    <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Receveur</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                

                                {
                                    transactions?.length > 0 ?   transactions?.map((row,index) => {
                                
                                        
                                    return (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                                
                                            </TableCell>
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {row?.calculWay} 
                                            </TableCell>
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {row?.bankFee} 
                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {row?.smallAmount}
                                            </TableCell>

                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {row?.maxAmount}
                                            </TableCell>
                                        </TableRow>
                                    )}) : 
                                        <TableRow>
                                            <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                                Aucune transaction effectué par ce client :)
                                            </TableCell>
                                        </TableRow>
                                }
                                
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </div>
                </div>
          </div>  
}

export default ClientTransactions;