import React, { Fragment } from "react";
import Transactions from "../transactions";
import { NavLink } from "react-router-dom";
import apiConfig from "../../../../../configs/config";

export default function Dashboard(props){

    const transactions = [];


    const baseUrl = apiConfig.baseUrl;
    
    const profile = props?.profileInformation;

    const sponsoringCode = profile?.code;


    return <Fragment>
                <div className="padding-top-2x mt-2 hidden-lg-up"></div>
                <div className="row">
                        <div className="col-lg-12 mb-4">
                            <div className="card border-start-lg border-start-primary">
                                <div className="card-body">
                                    <div className="small text-muted">Lien d'affiliation</div>
                                    <div className="h4 text-info">
                                       <NavLink 
                                            to={`${baseUrl+"/register/"+sponsoringCode}`}
                                            role="button" className="btn-link">
                                            {baseUrl+"/register/"+sponsoringCode}

                                       </NavLink> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 col-lg-4 mb-4">
                            <div className="card border-start-lg border-start-primary">
                                <div className="card-body">
                                    <div className="small text-muted">Nombre de point bonus</div>
                                    <div className="h3">{profile?.cumulatePoint || 0}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card border-start-lg border-start-secondary">
                                <div className="card-body">
                                    <div className="small text-muted">Nombre de transaction</div>
                                    <div className="h3">{profile?.transactionLength || 0}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                            <div className="card border-start-lg border-start-success">
                                <div className="card-body">
                                    <div className="small text-muted">Nombre de filleule</div>
                                    <div className="h3 d-flex align-items-center">{profile?.affiliatesLength || 0}</div>
                                </div>
                            </div>
                        </div>
                </div>
                <Transactions profileInformation={props?.profileInformation} transactions={transactions}/>
    </Fragment>
}