import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const profileFrameConfig = {
  name: 'profile',
  title: 'Profile',
};

export default BaseConfiguration (profileFrameConfig);
