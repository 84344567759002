import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class UsersQuery extends DefaultFirebaseQuery {
  constructor (params = null) {
    super ({
      name: 'users',
      path: 'users',
    });
  }

  login = async credentials => {
    const serverResponse = await this.controller.login (credentials);
    return serverResponse;
  };

  async create (credentials) {
    return await this.controller.register (credentials);
  }

  
}
