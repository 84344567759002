import {
    BaseConfiguration,
  } from '../../../vendor/base-component-class/config.class';
  
  const homeFrameConfig = {
    name: 'home',
    fields : [
      {
        name : 'taxes',
        htmlElement : 'select',
        options: [],
        optionsSrc : 'changetaxes'
      },
      {
        name : 'currencies',
        htmlElement : 'select',
        options: [],
        optionsSrc : 'currencies'
      }
    ]
  };
  
  export default BaseConfiguration (homeFrameConfig);
  