import DefaultController from './default.controller';

export default class AuthoritiesController extends DefaultController {
  formatToObject = [{field: 'resource', source: 'resources'}];

  rules = [
    {title: 'name', rules: ['required', 'unique:authorities:name']},
    {title: 'resource', rules: ['required']},
    {
      title: 'displayName',
      rules: ['required', 'unique:authorities:displayName'],
    },
  ];

  constructor () {
    super ({
      path: 'authorities',
    });
  }
}
