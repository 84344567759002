import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/images/logo.png';
import './register.css';
import {useState} from 'react';
import Button from '@mui/material/Button';
import * as React from 'react';

import {
  TextField,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { InputTypePhone } from '../../../vendor/builders/Form/Input';

const defaultTheme = createTheme ();

export default function CreateClientRender (props) {

  const [registerFormValues,setRegisterFormValues] = useState({});

  const auth = useSelector(state => state.auth);

  const handleFormChanges = (e,name = null, value = null) => {
    setRegisterFormValues({
      ...registerFormValues,
      [name || e.target.name] : value || e.target.value
    })
  }
  


  const onSubmitForm = (e) => {
    e.preventDefault();
    props.frameConfigs.onSubmitForm(e,
      {
        ...props.dialogInformation?.dialogContent?.fieldsData,
        email: {value : auth.user?.email} 
      }
    )
  }


  return (
    <div className='login-body bg-light'>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginY: 0,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <div className="form-container col-12 col-md-8 col-xl-8 col-lg-8">
           
            <Box
              component="form"
              onSubmit={onSubmitForm}
              noValidate
              sx={{my: 2}}
            >
                <div className="form-icon">
                  <img
                    className="rounded-circle logo"
                    alt="user-avatar"
                    src={logo}
                  />
                </div>
                  

              <div className="form-card my-2 col-12">
                <Typography component="h4" sx={{color: 'white',textAlign:'center',bgcolor:'gray',padding:2, marginBottom:1 }} variant="h5">
                  INFORMATION CLIENT
                </Typography>
              
                    <TextField
                        error={props?.dialogInformation?.dialogContent?.fieldsData?.name?.errors ? true : false}
                        sx={{ my:2 }}
                        required
                        label="Nom & prenom(s)"
                        fullWidth
                        name="name"
                        onChange={handleFormChanges}
                        onBlur={props.frameConfigs?.handleFormField}
                        autoComplete="false"
                        value={registerFormValues?.name|| ""}
                        helperText={props.dialogInformation?.dialogContent?.fieldsData?.name?.errors || null}
                      />

                      <InputTypePhone
                        error={props?.dialogInformation?.dialogContent?.fieldsData?.tel?.errors ? true : false}
                        value={registerFormValues?.tel|| ""}
                        changeValue = {(e,value = null,name = null) => {
                               handleFormChanges(e,name,value)
                            }
                        }
                        fieldInformation = {
                          {
                            label : 'Telephone',
                            name: 'tel'
                          }
                        }
                      />

                    <TextField
                        error={props?.dialogInformation?.dialogContent?.fieldsData?.address?.errors ? true : false}
                        sx={{ my:2 }}
                        required
                        label="Adresse"
                        fullWidth
                        name="address"
                        onChange={handleFormChanges}
                        onBlur={props.frameConfigs?.handleFormField}
                        autoComplete="false"
                        value={registerFormValues?.address|| ""}
                        helperText={props.dialogInformation?.dialogContent?.fieldsData?.address?.errors || null}
                      />
        

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    my: 1,
                    backgroundColor: '#000039',
                    borderColor: '#000039',
                    color: '#fff',
                    p: 2,
                  }}
                >
                  ENREGISTRER
                </Button>

              </div>

            </Box>

          </div>

        </Box>
      </Container>
    </ThemeProvider>
    </div>
  );
}


