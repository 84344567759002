import React from "react";
import '../../../../../assets/site/css/styles.css';


const Title = ({ title, subtitle }) => {
  return (
    <div className="site-title">
      <div className="review-heading">
          <h4>{title}</h4>
          <h3>{subtitle}</h3>
        </div>
    </div>
  );
};

export default Title;