import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { Fragment } from 'react';
import Box  from '@mui/material/Box';
import { capitalizeFirstLetter } from '../../../../bootstrap/Helpers/utils';


export default class SelectBuilder extends React.Component {

  constructor(props){
    super(props);
    this.changeSelectValue = this.changeSelectValue.bind(this);
    this.state = {
      value : null,
      error : null
    }
  }

  async componentDidUpdate(prevProps,prevState){
    if(this.props.fieldInformation !== prevProps.fieldInformation){
      if(this.props.fieldInformation.multiple){
          let valuesGet = [];

          if(this.props.fieldInformation?.defaultValue?.value){
            const defVal = this.props.fieldInformation?.defaultValue?.value;
            valuesGet = defVal; 
          }

          let options = [];
        
          valuesGet.forEach(
            valueGet => {
              const option = this.props.fieldInformation?.options?.find(option =>{
                if(typeof(option.value) !==  typeof(valueGet)){
                  return option.value+'' === valueGet+''
                }
                return option.value === valueGet
              });
              if(option){
                options = [...options, option]
              }
            }
          )
          
          await this.setState({
            value : options
          } ) ;

      }else{
          let valueGet = null;
          
          
          if(this.props.fieldInformation?.defaultValue?.value){
              const defVal = this.props.fieldInformation?.defaultValue?.value;
              valueGet = this.props.fieldInformation?.typeValueSent === "object" ? defVal.id : defVal; 
          }

            
            const currentIndex = this.props.fieldInformation?.options?.findIndex(option =>{
              return option.value === valueGet
            })

            await this.setState({
              value : currentIndex !== -1 ? this.props.fieldInformation?.options[currentIndex] : null } ) ;
        }
      } 
      if(this.props.fieldInformation?.defaultValue?.errors !== prevProps.fieldInformation?.defaultValue?.errors){
        await this.setState({error : this.props.fieldInformation?.defaultValue?.errors});
      }
  }

  async changeSelectValue(e,value){
    e.preventDefault();
     let toSend = value;
     
     if(this.props.fieldInformation?.typeValueSent === "object"){
      if(this.props.fieldInformation?.multiple){
        toSend = toSend.map(
            send => {
              return this.props.fieldInformation.options.find(option => option.value === send.value)?.originalObject
            }
        )
      }else{
        toSend = this.props.fieldInformation.options.find(option => option.value === value.value)?.originalObject
      }
     }else{
      if(this.props.fieldInformation?.multiple){
        toSend = toSend.map(
            send => {
              return send.value
            }
        )
      }else{
        toSend = value?.value
      }
     }

      
      this.props.fieldInformation?.handleChange(e,toSend,this.props.fieldInformation?.name)
  }

  render () {
    const selectInformation = this.props.fieldInformation;
    const defaultValue = selectInformation?.multiple ? [] : null;

    if(!selectInformation?.disable){
      return (
        <div className={`form-group col-${selectInformation?.col} my-2`}>
          {
            selectInformation?.groupBy ? 
            <Autocomplete
              multiple={selectInformation?.multiple || false}
              id={selectInformation?.id}
              onChange={this.changeSelectValue}
              options={selectInformation?.options || []}
              groupBy={option => option?.groupTitle || ""}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              name={selectInformation?.name}
              value = {this.state.value ||  defaultValue}
              renderInput={params => {
                return <TextField
                              {...params}
                              label={"Choisir "+ selectInformation?.label }
                              inputProps={{
                                ...params.inputProps,
                                readOnly: selectInformation?.disable,
                                placeholder:selectInformation?.label,
                                autoComplete: 'off', // disable autocomplete and autofill
                              }}
                              helperText={this.props.fieldInformation?.defaultValue?.errors || null}
                              error={this.props.fieldInformation?.defaultValue?.errors ? true : false}
                              disabled={selectInformation?.disable}
                          />
              }}
  
              renderOption={
                (props,option) => {
                  return <Fragment key={option.label}>
                            {
                              selectInformation?.imgOptions ? 
                              <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                  loading="lazy"
                                  width="50"
                                  height="25"
                                  srcSet={`${option?.imgLabelSrc
                                    || selectInformation?.imgOptions.defaultImgTarget} 2x`}
                                  src={`${option?.imgLabelSrc || selectInformation?.imgOptions.defaultImgTarget}`}
                                  alt="img"
                                />
                                {option.label}
                              </Box> : <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.label}
                              </Box> 
                            }
                        </Fragment>
                }
              } 
            />
            : 
            <Autocomplete
              multiple={selectInformation?.multiple || false}
              id={selectInformation?.id}
              onChange={this.changeSelectValue}
              options={selectInformation?.options || []}
              getOptionLabel={option => option.label}
              filterSelectedOptions
              name={selectInformation?.name}
              value = {this.state.value ||  defaultValue}
              renderInput={params => {
                return <TextField
                              {...params}
                              label={"Choisir "+ selectInformation?.label }
                              inputProps={{
                                ...params.inputProps,
                                placeholder:selectInformation?.label,
                                autoComplete: 'off', // disable autocomplete and autofill
                              }}
                              helperText={(this.props.fieldInformation?.defaultValue?.errors ) || null}
                              error={this.props.fieldInformation?.defaultValue?.errors?.length > 0 ? true : false}
                          />
              }}
  
              renderOption={
                (props,option) => {
                  return <Fragment key={option.label}>
                            {
                              selectInformation?.imgOptions ? 
                              <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                  loading="lazy"
                                  width="50"
                                  height="25"
                                  srcSet={`${option?.imgLabelSrc
                                    || selectInformation?.imgOptions.defaultImgTarget} 2x`}
                                  src={`${option?.imgLabelSrc || selectInformation?.imgOptions.defaultImgTarget}`}
                                  alt="img"
                                />
                                {option.label}
                              </Box> : <Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                  {option.label}
                              </Box> 
                            }
                        </Fragment>
                }
              } 
            />
          }
          
  
        </div>
      );
    }
    return <div className={`form-group col-${selectInformation?.col} my-2`}> 
                  <TextField
                      label={capitalizeFirstLetter(selectInformation?.label)}
                      id={selectInformation?.id}
                      value={this.props?.value}
                      fullWidth
                      name={selectInformation?.name}
                      max={selectInformation?.maxValue || null}
                      type={"text"}
                      disabled={selectInformation?.disable}
                  />
        </div>
  }
}
