import {Route, Routes, Navigate} from 'react-router-dom';
import BlogComponent from '../app-features/front/Blog';
import ContactusComponent from '../app-features/front/ContactUs';
import AboutusComponent from '../app-features/front/AboutUs';
import TeamScreen from '../app-features/front/Team/screen';
import BlogDetails from '../app-features/front/BlogDetails';
import HomeComponent from '../app-features/front/home';
import NotfoundComponent from '../app-features/commons/Notfound';
import RoutesBuilder from './router-builder';
import backRoutes from './back-route.json';
import HelpAndSupportComponent from '../app-features/front/HelpAndSupport';
import RegisterComponent from '../app-features/front/Register';
import CreateClientComponent from '../app-features/front/CreateClient';
import UnauthenticateComponent from '../app-features/commons/Unauthenticate';



function AppRouter (props) {
  return (
    <Routes>
      <Route exact path="/" element={<HomeComponent />} />
      <Route exact path="/blog" element={<BlogComponent />} />
      <Route exact path="/contactus" element={<ContactusComponent />} />
      <Route exact path="/aboutus" element={<AboutusComponent />} />
      <Route exact path="/help-and-support" element={<HelpAndSupportComponent />} />
      <Route exact path="/team" element={<TeamScreen />} />
      <Route exact path="/blog/:slug" element={<BlogDetails />} />
     
      <Route path="/notfound" element={<NotfoundComponent/>} />
      <Route path="/need-authentication" element={<UnauthenticateComponent/>} />
      <Route path="/client-add-info" element={<CreateClientComponent/>} />
      <Route path="/register/:sponsoringCode" element={<RegisterComponent/>} />
      {
        RoutesBuilder({
          target : 'front',
          routes : [
            { name : 'login'},
            { name : 'register'}
          ],
         })
      }
      {
        RoutesBuilder({
          routes : backRoutes,
          prefix : 'admin', 
          target : 'back',
          protections : ['login',"admin"]
         })
      }

      {
        RoutesBuilder({
          routes : [
            {name : 'new-transaction',component : 'TransactionForm'},
            {name : 'client-profile',component : 'Profile'},
          ],
          target : 'front',
          protections : ['login']
         })
      }
     
      <Route path="*" element={<Navigate to="/notfound" />} />
    </Routes>
  )
}

export default AppRouter;