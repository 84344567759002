export default class Service {
  name = null;

  constructor (params) {
    this.name = params?.name;
    
    if (params?.query) {
      this.query = params.query;
    } else {
      this.query = this.loadQueryParamsByClassName ();
    }
  }

  async all (params = null) {
    const data = await this.treatQuery (this.query.readAll (params));
    return {
      ...data,
      data : this.formatValue(data.data)
    };
  }

  async loadList (params = null) {
    const data = await this.treatQuery (this.query.loadList (params));
    return data;
  }

  async one (id) {
    return await this.treatQuery (this.query.readOne (id));
  }

  async update (payLoad, id) {
    return await this.treatQuery (this.query.update (id,this.getFormValues(payLoad)));
  }

  async create (payLoad) {
    let result = await  this.treatQuery (this.query.create (this.getFormValues(payLoad)));
    if(!result?.success && result?.errors?.length > 0){
     result.errors = this.giveErrorToTheGoodField(result.errors,payLoad);
    }
    return result;
  }

  loadQueryParamsByClassName () {
    const query = require (`../../external-communication/queries/${this.name?.toLowerCase ()}.query`)
      .default;
    return new query ();
  }

  async delete (id) {
    return await this.treatQuery (this.query.delete (id));
  }

  async treatQuery (toExec) {
    let response = {success: false, errors: null, data: null, message : null};

    const resultQuery = await toExec;
   
    if (resultQuery.success) {
      response.data = resultQuery?.data?.data ?? resultQuery?.data;
      
      response.errors = [];
      response.message = resultQuery.message;
    } else {
      response.errors = resultQuery.errors;
      response.data = null;
      response.message = resultQuery.message;
    }

    return {
      success: resultQuery.success,
      errors: response.errors,
      data: response.data,
      message : response.message
    };
  }

  formatValue(value){
    return value;
  }

  async validate (data, rules) {
    let errors = [];

     return errors;
  }

  getFormValues (fields) {
    let values = {};
    for (let field in fields) {
        if(Array.isArray(fields[field]?.value)){
          const formatValues = fields[field]?.value.map(
            val => {
              if(typeof(val) === "object"){
                  return this.getFormValues(val);
              }
              return val;
            }
          );
          values = {
            ...values,
            [field] : formatValues
          }
        }else{
          values = {
            ...values,
            [field]: fields[field]?.value,
          };
        }
        

      }
    return values;
  }

  giveErrorToTheGoodField(errors,fields){
    let errorResponseFormat = {};
    
    errors.forEach(
      error => {
        errorResponseFormat = {
          ...errorResponseFormat,
          [error?.name] : {
            errors : error?.errors?.join(','),
            value : fields[error?.name]?.value
          }
        }
      }
    )
   
    return errorResponseFormat;
  }
}
