import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";


const ClientAffiliates = props => {
    const affiliates = [];
    return <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive">
                    <TableContainer component={Paper}>
                    <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                        <TableHead sx={{ "& th": {
                            color: "white",
                            backgroundColor: "black"
                            }}}>
                            <TableRow>
                                <TableCell sx={{ fontWeight:"bold" }}>Nom & prenom(s)</TableCell>
                                <TableCell sx={{ fontWeight:"bold" }}>Tel.</TableCell>
                                <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Email</TableCell>
                                <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Adresse</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            

                            {
                                affiliates?.length > 0 ?   affiliates?.map((row,index) => {
                            
                                    
                                return (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                            
                                        </TableCell>
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.calculWay} 
                                        </TableCell>
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.bankFee} 
                                        </TableCell>
                                        
                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.smallAmount}
                                        </TableCell>

                                        <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                            {row?.maxAmount}
                                        </TableCell>
                                    </TableRow>
                                )}) : 
                                    <TableRow>
                                        <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                            Aucune transaction effectué par ce client :)
                                        </TableCell>
                                    </TableRow>
                            }
                            
                        </TableBody>
                    </Table>
                    </TableContainer>
                </div>
            </div>
}


export default ClientAffiliates;