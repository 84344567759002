import DefaultController from './default.controller';

export default class ProfileController extends DefaultController {

  async readAllWithSpecification (specifications, path = null) {
    const response = await this.readFirebase (path || this.path);
    // const formatData = this.completeFormat(dataFormatter.ListFormatterResponse (response?.data));
    return {
      ...response,
    };
  }

  async profile(){
    let sold = 0;
    let soldGift = 0;
    let sponsorings = [];
    let transactions = [];
   


    return {
      success : true,
      message : null,
      data : {
        sold,
        soldGift,
        sponsorings,
        transactions
      }
    }
  }
  
}
