import Service from './../../vendor/bases/service';

class DashboardService extends Service {
  constructor (params) {
    super ({
      ...params,
      name: 'dashboard',
    });
  }

  async all (params = null) {
    const data = await this.treatQuery (this.query.readAll (params));
    return {
      ...data,
      data : this.formatValue(data.data)
    };
  }
}

export default DashboardService;
