import React, { Fragment } from 'react';
import { SingleField } from '..';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class DynamicForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        fields :  this.props?.fieldInformation?.fields || [],
        values  : [],
        formFields : [],
        val : []
    }

    this.addNewField = this.addNewField.bind(this);
    this.removeField = this.removeField.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.formatToLocalValue = this.formatToLocalValue.bind(this); 

  }

  addNewField(e){
    let fieldsCollection = this.state.formFields || [];
    fieldsCollection.push(this.state.fields);
    this.setState({formFields : fieldsCollection || []})
  }

  removeField(e,index){
    let fieldsCollection = this.state.formFields;
    fieldsCollection?.splice(index,1);
    this.props.fieldInformation?.defaultValue.value?.splice(index,1);
    this.setState({formFields : fieldsCollection || []})  
  }

  async componentDidMount(){
    await this.setState({
        fields : this.props?.fieldInformation?.fields,
        formFields : [this.props?.fieldInformation?.fields],
        val : this.formatToLocalValue(this.props?.fieldInformation?.fields)
    })
  }


  formatToLocalValue(fields){
    let values = {};
    fields.forEach(element => {
        values = {
            ...values,
            [element.name] : {
                value : null
            }
        } 
    }); 
    return values;
  }


  async componentDidUpdate (prevProps,prevState) {
    if(this.props !== prevProps){
        if(this.props?.fieldInformation !== prevProps.fieldInformation){
            if(this.props?.fieldInformation?.fields !== prevProps?.fieldInformation?.fields){
                await this.setState({
                                    fields : this.props?.fieldInformation?.fields,
                                    formFields : [this.props?.fieldInformation?.fields]
                                })
            }

            if(this.props.fieldInformation?.defaultValue !== prevProps?.fieldInformation?.defaultValue ){

                let newLength  = this.props.fieldInformation?.defaultValue.value?.length || 1;
                    let fields = []; 
                    for(let i = 0; i<= newLength - 1;i++){
                        fields.push(this.state.fields);
                    }

                     
                    await this.setState({
                        formFields : fields,
                        values : this.props.fieldInformation?.defaultValue.value
                    });

            }
        }
    }
  }

  async handleChange(event,index,name = null,value=null){
    
    let lineValues = [...this.props.fieldInformation?.defaultValue.value || []];
    lineValues[index] = {
        ...lineValues[index],
        [name || event.target.name] : {
            ...lineValues[index]?.[name || event.target.name],
            value : value || event.target.value
        }
    };
   
    
    this.props.fieldInformation?.handleChange(event,lineValues,this.props.fieldInformation?.name)
    this.props?.fieldInformation?.subFormHandleChange(index,name || event?.target?.name,value || event?.target?.value,lineValues)

  }
  
  render () {
    const dynamicFormInformation = this.props.fieldInformation;
    return  <Fragment>
                <div className="form-group mt-2">
                    <hr/>
                        <strong>{dynamicFormInformation.label}</strong>
                    <hr/>
                    <div className="row">
                        {
                            this.state.formFields.map(
                                (field,index) => {
                                    return <Fragment key={"dynFields-"+index}> 
                                                <div className='col-11'>
                                                    <div className="row">
                                                        {field.map(
                                                            (item) => {
                                                                let val = null;
                                                                if(this.state.values){
                                                                    val = this.state.values[index]
                                                                }
                                                                
                                                                const localFieldHandle = (e,value = null,name = null) => {
                                                                    this.handleChange(e,index,item?.name,value)
                                                                }

                                                                return <div key={item?.name}  className={`${field?.length <= 4 ? 'col-'+12/field?.length : 'col-'+4}`}>
                                                                            <SingleField
                                                                                options = {this.props.fieldInformation?.options || []}
                                                                                value = {{ [item?.name] : val?.[item?.name] }} 
                                                                                field={{ 
                                                                                    ...item,
                                                                                    handleChange : localFieldHandle,
                                                                                }}
                                                                            />
                                                                        </div>
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                {
                                                  index  <= 0 &&  this.state.formFields.length <= 1  ? null :  <div className='align-center justify-content-start col-1'>
                                                  <IconButton 
                                                          onClick={(e) => this.removeField(e,index)} 
                                                          size="small" 
                                                          color="#fff"
                                                          sx={{ bgcolor : 'red', p :1, mt:3 }} 
                                                          aria-label="supprimer">
                                                      <FontAwesomeIcon color="#fff" icon="trash"/>
                                                  </IconButton>
                                              </div> 
                                                }   
                                                           
                                            </Fragment>

                                
                                }
                                
                            )
                        }
                    </div>
                    <span className="d-flex justify-content-end mt-2">
                        <IconButton 
                            sx={{ bgcolor : 'green', p :1 }} 
                            onClick={this.addNewField} 
                            size="large" 
                            color="#fff" 
                            aria-label="ajouter"
                        >
                            <FontAwesomeIcon color="#fff" icon="plus"/>
                        </IconButton>
                    </span>
                </div>
                <hr/>
            </Fragment>

            
  }
}


export class DynamicUnChangeForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            fields :  this.props?.fieldInformation?.fields || [],
            values  : [],
            formFields : [],
            val : []
        }
    }

    async componentDidMount(){
        await this.setState({
            fields : this.props?.fieldInformation?.fields,
            formFields : [this.props?.fieldInformation?.fields],
            val : this.formatToLocalValue(this.props?.fieldInformation?.fields)
        })
    }
    
    
    formatToLocalValue(fields){
        let values = {};
        fields.forEach(element => {
            values = {
                ...values,
                [element.name] : {
                    value : null
                }
            } 
        }); 
        return values;
    }
    
    
    async componentDidUpdate (prevProps,prevState) {
        if(this.props !== prevProps){
            if(this.props?.fieldInformation !== prevProps.fieldInformation){
                if(this.props?.fieldInformation?.fields !== prevProps?.fieldInformation?.fields){
                    await this.setState({
                                        fields : this.props?.fieldInformation?.fields,
                                        formFields : [this.props?.fieldInformation?.fields]
                                    })
                }

                if(this.props.fieldInformation?.defaultValue !== prevProps?.fieldInformation?.defaultValue ){
                    let newLength  = this.props.fieldInformation?.defaultValue.value?.length || 1;
                    let fields = []; 
                    for(let i = 0; i<= newLength - 1;i++){
                        fields.push(this.state.fields);
                    }
                    
                    await this.setState({
                        formFields : fields,
                        values : this.props.fieldInformation?.defaultValue.value
                    });

                    
                }
            }
        }
    }
    
      async handleChange(event,index,name = null,value=null){
        
        let lineValues = [...this.props.fieldInformation?.defaultValue.value || []];
        lineValues[index] = {
            ...lineValues[index],
            [name || event?.target?.name] : {
                ...lineValues[index]?.[name || event?.target?.name],
                value : value || event?.target?.value
            }
        };
        this.props.fieldInformation?.handleChange(event,lineValues,this.props.fieldInformation?.name);
        this.props?.fieldInformation?.subFormHandleChange(index,name || event?.target?.name,value || event?.target?.value,lineValues)
      }

      render () {
        const dynamicFormInformation = this.props.fieldInformation;
        return  <Fragment>
                    {
                        this.state.values ?  
                        <div className="form-group mt-1">
                            <hr/>
                            <strong>{dynamicFormInformation.label}</strong>
                            <hr/>
                            <div className="row">
                                {
                                    this.state.formFields.map(
                                        (field,index) => {
                                            return <Fragment key={"dynFields-"+index}> 
                                                        <div className='col-12 mb-2'>
                                                            <div className="row">
                                                                {field.map(
                                                                    (item) => {
                                                                        let val = null;
                                                                        if(this.state.values){
                                                                            val = this.state.values[index]
                                                                        }
                                                                        const localFieldHandle = (e,value = null,name = null) => {
                                                                            this.handleChange(e,index,name,value)
                                                                        }  
                                                                        return <div key={item?.name}  className={`${field?.length <= 4 ? 'col-'+12/field?.length : 'col-'+4}`}>
                                                                                    <SingleField
                                                                                        options = {this.props.fieldInformation?.options || []}
                                                                                        value = {val} 
                                                                                        field={{ 
                                                                                            ...item,
                                                                                            handleChange : localFieldHandle,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>   
                                                    </Fragment>
            
                                        
                                        }
                                        
                                    )
                                }
                            </div>
                            <hr/>
                        </div> : null
                    }
                   
                </Fragment>
                
      }
}