import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const clientsConfiguration = {
  name: 'clients',
  title: 'client(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom & Prenom(s)',
      col: '6',
    },
    {
      name: 'sponsor',
      title: 'Parrain',
      col: 6,
      htmlElement: 'select',
      type: 'objectFetcher',
      typeValueSent : 'object',
      dataServiceSource: 'clients',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'clients',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'tel',
      title: 'Téléphone',
      col: '4',
      type: 'phone',
    },
    {
      name: 'email',
      title: 'Email',
      col: 4,
    },
    {
      name: 'address',
      title: 'Adresse',
      col: 4,
    },
    
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'lg',
    },
  },
};

export default BaseConfiguration (clientsConfiguration);
