import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const currenciesConfiguration = {
  name: 'currencies',
  title: 'Devises',
  typeFrame: 'list',
  fields: [
    {
      name : "flag",
      title: 'Drapeau',
      type: 'user-avatar'
    },
    {
      name: 'name',
      title: 'Nom de la dévise',
      col: 4,
    },
    {
      name: 'symbol',
      title: 'Symbole',
      col: 4,
    },
    {
      name: 'code',
      col: 4,
    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  actions: [
    {
      name: 'edit',
      title: 'Modifier devise',
      color: 'success',
      icon: 'pen',
      
    },
    {
      name: 'valid',
      title: 'Active',
      color: 'info',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Desactiver',
      color: 'danger',
      icon: 'times',
      elementTest: 'state',
      value: 1,
      operator: 'equal',
    },
    {
      name: 'delete',
      title: 'Supprimer devise',
      color: 'danger',
      icon: 'trash',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (currenciesConfiguration);
