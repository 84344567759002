import {
  BaseConfiguration,
} from '../../../vendor/base-component-class/config.class';

const feesConfiguration = {
  name: 'fees',
  title: 'Frais',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
      col: 4,
    },
    {
      name: 'processFee',
      title: 'Frais de traitement',
      col: 4,
      type: 'percentage',
    },
    {
      name: 'calculWay',
      title: 'Mode de calcul',
      col: 4,
      htmlElement: 'select',
      options: [
        {
          label : 'Fixe',
          value : 'Fixe'
        },
        {
          label : 'Pourcentage',
          value : 'Pourcentage'
        }
      ]
    },
    {
      name: 'currency',
      title: 'Devise envoi',
      col: 6,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      typeValueSent : 'object',
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'currency2',
      title: 'Devise reception',
      col: 6,
      htmlElement: 'select',
      type: 'objectFetcher',
      options: [],
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      typeValueSent : 'object',
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'feesList',
      title: 'Frais & intervalles',
      fields: [
        {
          name: 'bankFee',
          title: 'Frais bancaire',
          col: 12,
          type: 'percentage',
        },
        {
          name: 'calculWay',
          title: 'Mode de calcul',
          htmlElement: 'select',
          options: [
            {
              label : 'Fixe',
              value : 'Fixe'
            },
            {
              label : 'Pourcentage',
              value : 'Pourcentage'
            }
          ]
        },
        {
          name: 'smallAmount',
          title: 'Montant minimum',
          col: 12,
          type: 'number',
        },
        {
          name: 'maxAmount',
          title: 'Montant maximum',
          col: 12,
          type: 'number',
        },
      ],
      htmlElement: 'dynamicForm',
    },

    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: ['state'],
  excludeToHeaders: ['feesList', 'processFee','calculWay'],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
  actions: [
    {
      name: 'view',
      title: 'voir',
      color: 'primary',
      icon: 'eye',
    },
    {
      name: 'edit',
      title: 'Modifier frais',
      color: 'success',
      icon: 'pen',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'valid',
      title: 'Active',
      color: 'info',
      icon: 'check',
      elementTest: 'state',
      value: 0,
      operator: 'equal',
    },
    {
      name: 'cancel',
      title: 'Desactiver',
      color: 'danger',
      icon: 'times',
      elementTest: 'state',
      value: 1,
      operator: 'equal',
    },
  ],

};

export default BaseConfiguration (feesConfiguration);
