import { Fragment } from 'react';
import './dashboard.css';
import moment from 'moment';

const DashboardRender = props => {
  
  let {totalClient,transactions} = {totalClient : 0, transactions : []};

  if(props.renderFrameConfigs.fetchData){
    totalClient = props.renderFrameConfigs.fetchData.totalClient;
    transactions = props.renderFrameConfigs.fetchData.transactions;
  }

  let totalTransactionsAmount =  0;

  if(transactions){
    transactions?.forEach(transaction => {
      totalTransactionsAmount += transaction.total;
    });
  }

  
  return <Fragment>
            <div className="row">
              <div className="col-xl-4 col-sm-6 col-12"> 
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-pencil primary font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{totalClient ? totalClient?.toLocaleString('fr-FR') : 0}</h3>
                          <span>CLIENTS</span >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-speech warning font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{transactions ? transactions?.length?.toLocaleString('fr-FR') : 0}</h3>
                          <span>TRANSACTIONS</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-sm-6 col-12">
                <div className="card">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="media d-flex">
                        <div className="align-self-center">
                          <i className="icon-graph success font-large-2 float-left"></i>
                        </div>
                        <div className="media-body text-right">
                          <h3>{totalTransactionsAmount ? totalTransactionsAmount?.toLocaleString('fr-FR') : 0}</h3>
                          <span>MONTANT TOTAL</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row p-2'>
              <div className='title text-white p-4'>HISTORIQUE DES TRANSACTIONS</div>

                <div className="card-body">
                  <div className='table table-responsive'>
                    <table className='table table-responsive table-stripped table-borderd'>
                        <thead>
                            <tr>
                              <th>Date & Heure</th>
                              <th>Envoyeur</th>
                              <th>Receveur</th>
                              <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                          {
                            transactions.length > 0 ? transactions.reverse().splice(10).map(
                              transaction => <tr key={transaction.id}>
                                                <td>{moment(transaction.dateTransaction).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                <td>{transaction.senderName}</td>
                                                <td>{transaction.recipientName}</td>
                                                <td>{transaction.total.toLocaleString('fr-FR')}</td>
                                            </tr> 
                            ) : <tr>
                                  <td className='text-center text-danger' colSpan={4}>Aucune transaction :)</td>
                            </tr>
                          }

                        </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </Fragment>;
};

export default DashboardRender;
