import { getAuth, GoogleAuthProvider } from "firebase/auth";
import firebaseApp from "./firebase.config";


let appAuthProvider = getAuth(firebaseApp);
let appAuthGoogleProvider = new GoogleAuthProvider(); 



export {appAuthProvider , appAuthGoogleProvider};
