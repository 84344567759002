import DashboardController from '../../../back-end/controllers/dashboard.controller';

export default class DashboardQuery {
  
  async readAll (searchOptions = null) {
    const controller = new DashboardController({name : 'dashboard'})
    
    return await controller.dashboardData();
  }

}
