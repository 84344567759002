import { Navigate } from "react-router-dom";
import ClientsService from "../../../external-communication/services/clients.service";
import BaseFrontComponent from "../base-front-component";


export default class ProfileComponent extends BaseFrontComponent{
    name = 'Profile'

    state = {
        ...this.state,
    }

    async componentDidMount(){
        await this.loadCurrentProfile()        
    }

    async loadCurrentProfile(){
        const getCurrentClientQuery = await new ClientsService ().getCurrentClientProfile();

        if(!getCurrentClientQuery.data){
            await this.setState({redirect : true});
        }else{
            await this.setState({data : getCurrentClientQuery.data})
        }

    }

    render () {
        if (this.state.redirect) {
          
            return <Navigate to={'/client-add-info'} />
        }
        const RenderScreen = this.loadScreen ();
        return <RenderScreen data={this.state} />;
      }
}