
import './style.css';
import fiche from '../../../../../assets/images/fiche.jpg';


const SingleRecipient = props => {
    const recipientInformation = props.data.rows;

    return  <div className="card-body information bg-light"> 
                 <div className="article-content">
                    <div className="p-2 text-center text-white article-title">
                        <h4>FICHE DE BENEFICIAIRE #{recipientInformation?.id}</h4>
                    </div>
                    <div className="card-content p-3 m-1">
                        <div className="d-flex align-items-center">
                            <div className="image bg-light p-2 mr-3">
                                <img src={fiche} alt="logo" className="rounded" width="120" />
                            </div>
                            <div className="p-2 w-100"> 
                                <h5 className="mb-0 mt-0">
                                    {recipientInformation?.name?.toUpperCase() || ""}</h5>
                                <small>BENEFICIAIRE</small>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                    <div className="d-flex flex-column">
                                        <span className="articles">
                                            N° de compte
                                        </span>
                                        <span className="number1">
                                            {recipientInformation?.accountNumber}
                                        </span>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span className="articles">
                                            Telephone
                                        </span>
                                        <span className="number1">
                                            {recipientInformation?.tel}
                                        </span>
                                    </div>
                                </div>
                            </div>     
                        </div>
                    </div>
                    <div className="card-content h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  article-title">
                                INFORMATION(S)
                            </h6>
                            <hr/>
                        </div>
                        
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Banque</h6>
                            </div>
                            <div className="col-sm-6 text-secondary">
                                {recipientInformation?.bank?.name?.toUpperCase()}        
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Devise</h6>
                            </div>
                            <div className="col-sm-6 text-secondary">
                                {recipientInformation?.currency?.name?.toUpperCase()}        
                            </div>
                        </div>
                        <hr />
                         <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Adresse</h6>
                            </div>
                            <div className="col-sm-6 text-secondary">
                                {recipientInformation?.address?.toUpperCase()}        
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-3">
                                <h6 className="mb-0">Email</h6>
                            </div>
                            <div className="col-sm-6 text-secondary">
                                {recipientInformation?.email}        
                            </div>
                        </div>        
                    </div>
                </div>

                </div>
            </div>
}

export default SingleRecipient;