import createImageUrlBuilder from '@sanity/image-url'
import { createClient } from '@sanity/client'

export const client = createClient ({
      projectId: "1e1q56v9",
      dataset: "production",
      apiVersion: "2023-07-09",
      useCdn: true,
      token: "skCDBFcEGwujTCj9Z163cSznXtstLySLDNopSymk6tvIy1mzpKrK3htgCgcO5DlUBAudgdYLYRfl79VuTW2TdtgVkUK1lYO2dbNPuTBLATmi7eElxWAZJcLEYzI1FSNgInNtrmcoMj4j05KaHUxXuYwNlWKyqozuxMuwWlrCCwiJiSVIaYo8",
});

const builder = createImageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);