import React, { useState } from "react";
import "./Review.css";
import { useEffect } from "react";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import mainTheme from "../../../../configs/mainTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const CardReview = ({ review }) => {
  const [readMore,setReadMore]= useState(0);
  
  useEffect(() => {
    if(review.review.length > 185){
      setReadMore(1);
    }else{
      setReadMore(0);
    }
  },[readMore,review])
  
  /*const openOrCloseReadMore = (value) => {
    setReadMore(value);
  }*/

  return (
      <div className="col-12 card border-0 border-bottom border-primary shadow-sm">
        <div className="col-12 card-body">
          <CardMedia
            component="img"
            alt={review?.name}
            width={"100%"}
            height={"150px"}
            className="img img-fluid mb-4 border border-3 card-image"
            image={review?.profileImage === null ? mainTheme.appLogo : review?.profileImage.asset.url}
          />
          <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {review?.name}
                </Typography>
                <Typography gutterBottom variant="body2" component="div">
                  {review?.title}
                </Typography>
                <div className="review-card">
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      <FontAwesomeIcon icon='quote-left' /> 
                      <span className="mx-2">
                      {
                          review?.review 
                        }
                      </span>  
                      <FontAwesomeIcon icon='quote-right' /> 
                    
                        
                  </Typography>
                </div>
                
          </CardContent>
       
        </div>
      </div>
  );
};

export default CardReview;
