import "./style.css";
import fiche from '../../../../../assets/images/fiche.jpg';
import { capitalizeFirstLetter } from "../../../../../bootstrap/Helpers/utils";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const SingleFee = props => { 
    const feeInformation = props.data.rows;


    return <div className="card-body bg-light">
                <div className="p-2 text-center text-white fee-card-title">
                    <h4>FICHE DE FRAIS ENVOI  #{feeInformation?.name}</h4>
                </div>
                <div className="card-content p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">
                                Frais ID : {capitalizeFirstLetter(feeInformation?.id?.toUpperCase() || "")}</h5>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Devise envoie</span>
                                    <span className="number1">
                                        {feeInformation?.currency?.name?.toUpperCase()}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Devise de reception</span>
                                    <span className="number1">
                                        {feeInformation?.currency2?.name?.toUpperCase()}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
                <div className="card-content h-100 m-1">
                    <div className="card-body">
                        <div>
                            <h6 className="d-flex align-items-center p-2 text-white  fee-card-title">
                                REPARTITION DES FRAIS
                            </h6>
                            <hr/>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="table-responsive">
                                <TableContainer component={Paper}>
                                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                                    <TableHead sx={{ "& th": {
                                        color: "white",
                                        backgroundColor: "black"
                                        }}}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight:"bold" }}>DESIGNATION</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Mode calcul</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Valeur</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Montant min.</TableCell>
                                            <TableCell sx={{ fontWeight:"bold",textAlign : 'center' }}>Montant max.</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                            <TableCell component="td" sx={{fontWeight : 'bold'}}>
                                                {"Frais de traitement"}
                                            </TableCell>
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {feeInformation?.calculWay} 
                                            </TableCell>
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                {feeInformation?.processFee} 
                                            </TableCell>
                                            
                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                Non defini
                                            </TableCell>

                                            <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                Non defini
                                            </TableCell>
                                        </TableRow>

                                        {
                                            feeInformation?.feesList?.length > 0 ?   feeInformation?.feesList?.map((row,index) => {
                                            
                                            let feeName = 'Frais ';

                                            if(row.smallAmount && row.maxAmount){
                                                feeName += `[${row.smallAmount +'-'+row.maxAmount}]`
                                            }else if(row.smallAmount && !row.maxAmount){
                                                feeName += `[${' > ou = '+row.smallAmount}]`
                                            }else if(!row.smallAmount && row.maxAmount){
                                                feeName += `[${' < ou = '+row.maxAmount}]`
                                            }
                                                
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="td" sx={{fontWeight : 'bold'}} scope="articleName">
                                                        {feeName.toUpperCase()}
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.calculWay} 
                                                    </TableCell>
                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.bankFee} 
                                                    </TableCell>
                                                    
                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.smallAmount}
                                                    </TableCell>

                                                    <TableCell sx={{ textAlign : 'center',fontWeight : 'bold' }}>
                                                        {row?.maxAmount}
                                                    </TableCell>
                                                </TableRow>
                                            )}) : 
                                                <TableRow>
                                                    <TableCell sx={{ textAlign:"center" }} colSpan={5} component="td" scope="row">
                                                        Aucun frais defini sur cette fiche de frais :)
                                                    </TableCell>
                                                </TableRow>
                                        }
                                        
                                    </TableBody>
                                </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
}


export default SingleFee;