import dataFormatter from '../responses/row-formatter.response';
import DefaultController from './default.controller';

export default class PromotionsController extends DefaultController {
  
  rules = [
    {title: 'name', rules: ['required']},
    {title: 'exonerationTaxe', rules: ['required']},
  ];

  constructor () {
    super ({
      path: 'promotions',
    });
  }

  async otherTreatCredentials (credentials){
    return {
      ...credentials,
      code : await this.generateCodePromo(),
      state : 1
    };
  }

  async activePromotion(id){

    const response = await this.readFirebase (
      `${('promotions' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 1
    };


    return this.update(id,data)
  }


  async disablePromotion(id){
    const response = await this.readFirebase (
      `${('promotions' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 0
    };
    return this.update(id,data)
  }

  async generateCodePromo(){
    let codePromo = Math.floor(Math.random() * (999999 - 100000) + 100000);
    
    const codeChecking = await this.checkCodePromoExistence(codePromo);

    if(codeChecking){
       codePromo = await this.generateCodePromo()    
    }

    return codePromo;
  }

  async checkCodePromoExistence(code){
    const response = await this.readFirebase (
        `${('promotions' || this.path)}`
    );

    const list = dataFormatter.ListFormatterResponse(response.data);

    const promotionByCodeIndex = list.findIndex(promotion => promotion.code === code);

    return promotionByCodeIndex !== -1;
  }
}
