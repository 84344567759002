import {Fragment} from 'react';
import ScrollToTop from './_layouts/ScrollToTop';
import Navbar from './_layouts/Navbar';
import Footer from './_layouts/Footer';
import SnackbarBuilder from '../back/_layouts/snackbar';

export default function BaseFrontTemplate (props) {
  const Component = props.frameConfigs.render;
  
  return <Fragment>
            <div className="">
              <ScrollToTop />
              <Navbar />
              <Component {...props}/>
              <Footer />
              <SnackbarBuilder snackProperties={props.snackBarInformation}/>
            </div>  
          </Fragment>
}
