import React, { Fragment, useState } from 'react';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dashboard from './_layouts/dashboard';
import Affiliates from './_layouts/affiliates';
import Transactions from './_layouts/transactions';
import Account from './_layouts/account';
import EditProfile from './_layouts/edit-profile';
import UsersService from '../../../external-communication/services/users.service';
import userAction from '../../../global-state/actions/user.action';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';



export default function ProfileRender(props){
    const profileInformation = props.frameConfigs?.fetchData; 
    const components = [
        {
            menuTitle : "Tableau bord",
            component: Dashboard(),
            name : 'dashboard'
        },
        {
            menuTitle : "Information compte",
            component: Account(),
            name: 'account'
        },
        {
            menuTitle : "Transction(s)",
            component: Transactions(),
            name: 'transactions'
        },
        {
            menuTitle : "Affilie(es)",
            component: Affiliates(),
            name: 'affiliates'
        },
        {
            menuTitle : null,
            component: EditProfile(),
            name: 'edit-profile',
            hideInMenu: true
        },
    ]
    const [currentComponentName,setCurrentName] = useState("dashboard");
    const [redirect,setRedirect] = useState(false);

    const dispach = useDispatch();


    const changeCurrentComponent = (e,name) => {
        e.preventDefault();
        setCurrentName(name)
    }

    const logout = async (e) => {
        await new UsersService().logout();
        dispach(userAction.logout())  
        setRedirect(true);   
    }

    const ComponentRender = (props) => {
       
        return <Fragment>
                    {
                        currentComponentName === 'dashboard' ? <Dashboard {...props}/> : null
                    }
                    {
                        currentComponentName === 'account' ? <Account {...props}/> : null
                    }
                    {
                        currentComponentName === 'transactions' ? <Transactions {...props}/> : null
                    }
                    {
                        currentComponentName === 'affiliates' ? <Affiliates {...props}/> : null
                    }
                    {
                        currentComponentName === 'edit-profile' ? <EditProfile {...props}/> : null
                    }
                </Fragment>
        
    } ; 

    if(redirect){
        return <Navigate to={'/login'} />
    }

   return <div className="profile-main container padding-bottom-3x my-5 p-3">
    <div className="row">
        <div className="col-lg-4">
            <aside className="user-info-wrapper border-bottom-3 card">
                <div className="user-info">
                    <div className="user-avatar">
                        <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="User"/>
                    </div>
                    <div className="user-data">
                        <h4>{profileInformation?.name}</h4>
                        <span    
                            onClick={(e) => { changeCurrentComponent(e,"edit-profile")}}
                            role="button" className='btn btn-sm btn-success text-white my-2 p-1 col-6'
                        >
                            Editer
                        </span>
                        <span    
                            onClick={logout}
                            role="button" className='btn btn-sm btn-danger text-white my-2 p-1 col-6'
                        >
                            Deconnexion
                        </span>
                    </div>
                </div>
                
            </aside>
            <hr/>

            <nav className="list-group">
                {
                    components.map(
                        component => {
                            if(!component.hideInMenu){
                            return <span key={component.name} 
                                            role="button"
                                            className={`${component.name === currentComponentName ? "active-side-link fw-bold list-group-item cursor-grab" : "list-group-item cursor-grab"} `}
                                            onClick={(e) => { changeCurrentComponent(e,component.name)}}
                                        >
                                            <i className='mr-3'>
                                                <FontAwesomeIcon icon="angles-right"/>
                                            </i>
                                            {component.menuTitle}
                                        </span>
                            }
                            return null;
                        }
                    )
                }
                
            </nav>
        </div>
        <div className="col-lg-8">
          <ComponentRender {...props} profileInformation={profileInformation}/>   
        </div>
    </div>
</div>
}


/*function ProfileRender(props){
    

    const profileInformation = props.frameConfigs?.fetchData; 

    const [currentComponentName,setCurrentName] = useState("dashboard");
    const [redirect,setRedirect] = useState(false);

    const dispach = useDispatch();


    const changeCurrentComponent = (e,name) => {
        e.preventDefault();
        setCurrentName(name)
    }

    const logout = async (e) => {
        await new UsersService().logout();
        dispach(userAction.logout())  
        setRedirect(true);   
    }

    const ComponentRender = (props) => {
       
        return <Fragment>
                    {
                        currentComponentName === 'dashboard' ? <Dashboard {...props}/> : null
                    }
                    {
                        currentComponentName === 'account' ? <Account {...props}/> : null
                    }
                    {
                        currentComponentName === 'transactions' ? <Transactions {...props}/> : null
                    }
                    {
                        currentComponentName === 'affiliates' ? <Affiliates {...props}/> : null
                    }
                    {
                        currentComponentName === 'edit-profile' ? <EditProfile {...props}/> : null
                    }
                </Fragment>
        
    } ; 

    if(redirect){
        return <Navigate to={'/login'} />
    }

    return <div className="profile-main container padding-bottom-3x my-5 p-3">
                    <div className="row">
                        <div className="col-lg-4">
                            <aside className="user-info-wrapper border-bottom-3 card">
                                <div className="user-info">
                                    <div className="user-avatar">
                                        <img src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="User"/>
                                    </div>
                                    <div className="user-data">
                                        <h4>{profileInformation?.name}</h4>
                                        <span    
                                            onClick={(e) => { changeCurrentComponent(e,"edit-profile")}}
                                            role="button" className='btn btn-sm btn-success text-white my-2 p-1 col-6'
                                        >
                                            Editer
                                        </span>
                                        <span    
                                            onClick={logout}
                                            role="button" className='btn btn-sm btn-danger text-white my-2 p-1 col-6'
                                        >
                                            Deconnexion
                                        </span>
                                    </div>
                                </div>
                                
                            </aside>
                            <hr/>

                            <nav className="list-group">
                                {
                                    components.map(
                                        component => {
                                            if(!component.hideInMenu){
                                            return <span key={component.name} 
                                                            role="button"
                                                            className={`${component.name === currentComponentName ? "active-side-link fw-bold list-group-item cursor-grab" : "list-group-item cursor-grab"} `}
                                                            onClick={(e) => { changeCurrentComponent(e,component.name)}}
                                                        >
                                                            <i className='mr-3'>
                                                                <FontAwesomeIcon icon="angles-right"/>
                                                            </i>
                                                            {component.menuTitle}
                                                        </span>
                                            }
                                            return null;
                                        }
                                    )
                                }
                                
                            </nav>
                        </div>
                        <div className="col-lg-8">
                          <ComponentRender {...props} profileInformation={profileInformation}/>   
                        </div>
                    </div>
            </div>
}





export default  ProfileRender;*/