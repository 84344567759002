import React, { useState } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";

import Well from '../../../../../assets/site/img/well.png';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  
  const authUser = useSelector(state => {return state.auth});

  const user = authUser?.user;

  const handleShowNavbar = () => {
    setShowNav(!showNav);
  };

  return (
      <div className="navbar nav-container well-nav">
        <NavLink to="/">
          <div className="logo-nav">
            <img src={Well} alt="Well Icon" />
            <div className="p-2">
              <h5>WELL TRANSFER </h5>
              <small>Fast Secure Affordable</small>
            </div>
          </div>
        </NavLink>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <HiOutlineMenuAlt3 />
        </div>
        <div className={`nav-elements  ${showNav && "active"}`}>
          <ul>
            <div className="cross" onClick={handleShowNavbar}>
              <RxCross2 />
            </div>
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/aboutus">À propos de nous</NavLink>
            </li>

            <li>
              <NavLink to="/help-and-support">Aides et supports</NavLink>
            </li>
            <li>
              <NavLink to="/contactus">Contactez-nous</NavLink>
            </li>
            {!user ? (
                  <>
                    <li>
                      <NavLink className="getstarted" to="/login">Connexion</NavLink>
                    </li>
                    <li>
                      <NavLink className="getstarted" to="/register">
                        Créer un compte
                      </NavLink>
                    </li>
                  </>
                ) : (
                  (user?.roles?.name?.toUpperCase() === "CONFIGURATEUR" || user?.roles?.name?.toUpperCase() === "ADMIN") ? 
                    <li>
                      <NavLink className="getstarted p-3" to="/admin/dashboard">
                        <small className="mr-4">
                          <FontAwesomeIcon icon="user"></FontAwesomeIcon>
                        </small>
                        <span className="mx-2">Tableau de bord</span>
                      </NavLink>
                    </li>
                  :
                  <li>
                    <NavLink className="getstarted p-2 text-center" to="/client-profile">
                      <small className="mr-4">
                        <FontAwesomeIcon icon="user"></FontAwesomeIcon>
                      </small>
                      <span className="mx-2">Mon espace</span>
                    </NavLink>
                  </li>
                )}
              
          </ul>
        </div>
      </div>
  );
};

export default Navbar;
