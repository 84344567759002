const filterData = (data, options) => {
  let donnees = data;
  for (let key in options) {
    if (options[key] !== null && options[key] !== '') {
      donnees = filter (options[key], key, data);
    }
  }

  return donnees;
};

function filter (value, name, data) {
  if(value && (name !== "page" && name !== "size" && name !== "sort")){
    return data.filter (item => item[name]?.includes (value));
  }
  return data;
}

export default filterData;
