import "./style.css";
import fiche from '../../../../../assets/images/fiche.jpg';

const SingleChangeTaxe = props => { 
    const promotionInfromation = props.data.rows;


    return <div className="card-body bg-light">
                <div className="p-2 text-center text-white fee-card-title">
                    <h4>Promotion  #{promotionInfromation?.id}</h4>
                </div>
                <div className="card-content p-3 m-1">
                    <div className="d-flex align-items-center">
                        <div className="image bg-light p-2 mr-3">
                            <img src={fiche} alt="logo" className="rounded" width="120" />
                        </div>
                        <div className="p-2 w-100"> 
                            <h5 className="mb-0 mt-0">
                             {promotionInfromation?.name?.toUpperCase()}  
                            </h5>
                                <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white stats">
                                <div className="d-flex flex-column">
                                    <span className="articles">Code promotion</span>
                                    <span className="number1">
                                        {promotionInfromation?.code?.toLocaleString('fr-FR')}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Mode de calcul</span>
                                    <span className="number1">
                                        {promotionInfromation?.calculWay}
                                    </span>
                                </div>
                                <div className="d-flex flex-column">
                                    <span className="articles">Taux de reduction</span>
                                    <span className="number1">
                                        {promotionInfromation?.exonerationTaxe}
                                    </span>
                                </div>
                            </div>
                        </div>     
                    </div>
                </div>
            </div> 
}


export default SingleChangeTaxe;