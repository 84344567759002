import UsersService
  from '../../../external-communication/services/users.service';
import userAction from '../../../global-state/actions/user.action';
import {connect} from 'react-redux';
import { Navigate } from 'react-router-dom';
import BaseFrontComponent from '../base-front-component';

class LoginComponent  extends BaseFrontComponent {
  name = "Login";
  state = {
    ...this.state,
  };

  

  async submitForm (e,signInType) {
    e.preventDefault ();
    const service = new UsersService ();
    const serviceResponse = await service.login ({...this.state.formPayload,...signInType});
    if(serviceResponse.success){
      this.loadFormMessage('success',"Connecté avec succès redirection");
      const loginAction = userAction.login (serviceResponse?.data?.user);
      this.props.dispatch (loginAction);

      await this.setState ({
        redirect: true,
        auth: serviceResponse?.data?.user
      });
      
    }else{
      this.loadFormMessage('error',serviceResponse.message,serviceResponse?.errors)
    }
   
  }

  render () {
    if (this.state.redirect || this.state.auth) {
      if(this.state.auth.roles?.name?.toUpperCase() === "CONFIGURATEUR" || this.state.auth.roles?.name?.toUpperCase() === "ADMIN"){
        return <Navigate to={'/admin/dashboard'} />
      }else{
        return <Navigate to={'/client-profile'} />
      }   
    }
    const RenderScreen = this.loadScreen ();
    return <RenderScreen data={this.state} />;
  }

}

const mapStateToProps = state => state;

export default connect (mapStateToProps) (LoginComponent);
