import React from 'react';
import UsersService from './../../external-communication/services/users.service';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import userAction from '../../global-state/actions/user.action';

export default class BaseComponent extends React.Component {
  name = null;
  options = [];

  constructor (props) {
    super (props);
    this.loadScreen = this.loadScreen.bind (this);
    this.loadExternalCommunicationService = this.loadExternalCommunicationService.bind (
      this
    );
    this.loadComponentDataFromDatabase = this.loadComponentDataFromDatabase.bind (
      this
    );
    this.otherSingleDataInit = this.otherSingleDataInit.bind(this);
    this.loadFormMessage = this.loadFormMessage.bind(this);
    this.onHandleFormField = this.onHandleFormField.bind (this);
    this.initDataLoading = this.initDataLoading.bind(this);
    this.onValidOtherAction = this.onValidOtherAction.bind(this);
    this.submitForm = this?.submitForm?.bind (this);
    this.logout = this.logout.bind(this);
    this.loadFieldOptions = this.loadFieldOptions.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.singleDataId = this.singleDataId.bind(this);
    this.resetPayload = this.resetPayload.bind(this);
    this.handleSearchOptions = this.handleSearchOptions.bind(this);
    this.otherHandleFieldTreatment = this.otherHandleFieldTreatment.bind(this);
    this.handleSubForm = this.handleSubForm.bind(this);
    this.afterSubmitAction = this.afterSubmitAction.bind(this);
      
    this.state = {
      formPayload: {},
      closeDialog : false,
      snackInformation : null,
      data : null,
      dataSimpleList:[],
      singleData : null,
      screen: null,
      options : null,
      originalOptionsData:{},
      searchOptions : {
        page : 0,
        size : 50,
        sort : 'id,desc',
      },
      screenActions:{   
        validOtherAction : this.onValidOtherAction,
        handleFormField: this.onHandleFormField,
        submitForm: this.submitForm,
        logout : this.logout,
        loadSingleData : this.singleDataId,
        resetPayload : this.resetPayload,
        handleSearch : this.handleSearchOptions,
        handleSubForm : this.handleSubForm
      },
    };
  }

  loadScreen () {
    const target = this.state.target || 'commons';
    const screen =
      this.state.screen ||
      require (`../../app-features/${target}/${this.name}/screen`).default;
   return screen;
  }

  initDataLoading(){
    this.loadComponentDataFromDatabase (null,this.state.searchOptions);
  }

  async resetPayload(){
    await this.setState({
      formPayload : {},
      singleData : {}
    })
  }


  async loadComponentDataFromDatabase (id = null,options = null) {
   
    const service =
    this.state.service || this.loadExternalCommunicationService ();
   
    if (service) {
      let data = null;
      //let dataSimpleList = [];
      if(id){
        data = await service.one (id);
      }else{
        data = await service.all (options);
      }
      if(!data.success){
        this.loadFormMessage('error',data?.message || "Echec lors de la récupération");
      }
      await this.setState (() => ({
        ...this.state,
        data:  data?.data,
        //dataSimpleList : dataSimpleList?.data
      }));
    }
  }

  loadExternalCommunicationService () {
    try{
      let service = require (`../../external-communication/services/${this.name.toLowerCase ()}.service`)
        .default;

        if (service) {
          return new service ();
        }
    }catch(exception){
        return null;
    }
    
  }

  async onHandleFormField (e,inputValue = null,inputName = null) {
    let name = inputName || e?.target?.name;
    let value = inputValue !== null && inputValue !== undefined ? inputValue : e?.target?.value;
    if(value === "null-img"){
      value = null;
    }


    await this.setState(
      state => ({
        ...this.state,
        formPayload : {
          ...this.state.formPayload,
          [name] : {
            ...this.state.formPayload?.[name],
            value : value 
          }
        }
      })
    );

    this.otherHandleFieldTreatment(name,value)
  }

  async handleSubForm(index,name,value){}

  async otherHandleFieldTreatment(name,value){}

  async submitForm (e, name = null, payload = null) {
    e?.preventDefault ();
    const payLoad = payload || this.state.formPayload;

    let response = null;
    const actionName = e?.target[e.target.length - 1];
    let message = '';
    
    const action =  name || actionName?.name;


    const service =  this.state.service || this.loadExternalCommunicationService();

    switch (action){
      case "add" : 
            message = "insertion de la donnée"; 
            response = await service.create(payLoad);  
            break;
      case "edit" : 
            message = "modification de la donnée";
            response = await service.update(payLoad,payLoad?.id?.value); 
            break;      
      default : console.error('non valid action'); break;
    }
    if(response?.success){
      this.setState({closeDialog : !this.state.closeDialog});
      this.loadFormMessage('success',"Succès "+message);
      this.resetPayload();
      this.initDataLoading();
      this.afterSubmitAction(response?.data);
    }else{
      this.loadFormMessage('error',response?.message || 'Echec '+message,response.errors);
    }
  }

  afterSubmitAction(data){}

  async loadFormMessage(type, message, errors = []){
    let messageFormRender = null;
    if(type === "success"){
      messageFormRender = {
        errors : [],
        success : true,
        message : message,
        color : 'success'
      }
    }else if(type === "error"){
      messageFormRender = {
        errors : errors ,
        success : null,
        message : message,
        color : 'error' 
      }
    }

    if(messageFormRender){
      this.setState({
        snackInfo : {
          isOpen : true,
          message : messageFormRender.message,
          color : messageFormRender.color     
        },
        formPayload : {
          ...this.state.formPayload,
          ...errors
        }
      })
    }
  }

  //Other action as delete, logout,...
  onValidOtherAction = async (e) => {
    e.preventDefault();
    let response = null;
    const dataId =  e.target.id;
    const service =  this.state.service || this.loadExternalCommunicationService();
    let message = "";

    switch(e.target.name){
      case "lock" :
            message = "vérouillage";  
            response = await service?.lockOrUnlock(dataId); 
          break; 
      case "unlock" :
            message = "dévérouillage";  
            response = await service?.lockOrUnlock(dataId); 
          break;
      case "valid" :
            message = "validation";  
            response = await service?.valid(dataId); 
          break;
      case "cancel" :
            message = "annulation";  
            response = await service?.cancel(dataId); 
          break;          
      case "delete" : 
            message = "suppression";  
            response = await service?.delete(dataId); 
          break; 
      case "close" : 
          message = "fermeture";  
          response = await service?.close(dataId); 
        break; 
      case "approved" : 
          message = "approbation";  
          response = await service?.approved(dataId); 
        break; 
      case "performed" : 
          message = "exécution";  
          response = await service?.performed(dataId); 
        break;   
      case "logout" :  
            message = "déconnexion";
            response = await this?.logout(e,dataId); 
            const loggoutAction = userAction.logout ();
            this.props.dispatch (loggoutAction);
          break; 
      default : console.error("no action available"); break;
    }

    if(response?.success){
      this.initDataLoading();
      this.setState({closeDialog : !this.state.closeDialog});
      this.loadFormMessage("success",response?.message || "Succès "+message);
    }else{
      this.setState({closeDialog : true});
      this.loadFormMessage("error",response?.message || "Echec "+message);
    }
  }

  async logout(e, userId = null){
    e.preventDefault();
    const userService = new UsersService();
    const result = await userService.logout();
    this.loadFormMessage("success",result?.message);
    this.setState({
       logoutRedirection : true
    });
   // window.location.reload();
    //const logoutAction = userAction.logout ();
    //this.props.dispatch (logoutAction);
  }

  async loadFieldOptions(params){
    let service = require (`../../external-communication/services/${params?.source}.service`)
      .default;
    let data = [];
    const method = params?.methodForData || "loadList";
    if (service) {
      const serviceForLoading = new service ();
      data = await serviceForLoading[method](params.parameter);
    }

    let originalData = this.state.originalOptionsData;

    originalData[params?.source] =  data?.data; 
    
    

    await this.setState({
      originalOptionsData : originalData
    });

    return data?.data?.map(
      option => {
        let label = "";
        let groupTitle = "";
         if(params?.dataToGetInSource?.length > 0){
          params?.dataToGetInSource?.forEach(
            item => {
              label += option?.[item]+" "
            }
          )
         }

         if(params?.dataToGetForGroupTitle){
            params?.dataToGetForGroupTitle.forEach(
              item => {
                groupTitle += option?.[item]+" "
              }
            )
         }


        return {
          label : label,
          value: params?.optionValue ? option?.[params?.optionValue] : option?.id,
          imgLabelSrc : option?.[params?.imgTarget],
          groupTitle : groupTitle !== "" ? groupTitle : null,
          originalObject: option
        }
      }
    );
    
  }

  async loadOptions(){
    let allOptions = [];

    this.options.forEach(
      async option => {
        const title  = option.title; 
        const getOptions = await this.loadFieldOptions({source : option.source || option.title,
                                                          dataToGetInSource : option.keys || ['id'],
                                                          dataToGetForGroupTitle : option.dataToGetForGroupTitle || ['id'],
                                                          imgTarget : option.imgTarget || ['id'],
                                                          methodForData : option.method,
                                                          optionValue : option.optionValue
                                                        });
          if(!allOptions.includes(opt => opt.title === title)){
            allOptions.push({
              title : title,
              options : getOptions
            });
          }                                              

          

      }
    )



    await this.setState(
      () => ({
        options : allOptions
      })
    );    

  }

  async singleDataId(id){
    const service =  this.state.service || this.loadExternalCommunicationService();

    const response = await service?.one(id);

    if(response.success){
        const info = response?.data;
        let infoResponseFormat = {};
        for (let field in info) {
          infoResponseFormat = {
            ...infoResponseFormat,
            [field] : {
              errors : [],
              value : info?.[field]
            }
          }
        }
        await this.setState({
          ...this.state,
          formPayload : this.otherSingleDataInit(infoResponseFormat),
          singleData : response?.data
        })
    }
    
  }

  otherSingleDataInit(data){
    return data;
  }

  async handleSearchOptions(e,value = null,name=null){
      const optionValue = value || e.target.value;
      const optionName = name || e.target.name;
      await this.setState({
        searchOptions : {
          ...this.state.searchOptions,
          [optionName] : optionValue
        }
      });

      this.callSearchOptionsAfterChanges(this.state.searchOptions);
  }

  callSearchOptionsAfterChanges(options){
    this.loadComponentDataFromDatabase(null,options);
  }

  render () {
    const RenderScreen = this.loadScreen ();
    if(this.state.logoutRedirection){
      return <Navigate to="/login"/>
    }
    return <RenderScreen data={this.state} />
  }
}


const mapStateToProps = state => state;

connect (mapStateToProps) (BaseComponent);