import { Autocomplete, TextField } from "@mui/material";
import { Fragment, useState } from "react";


const AccountInfo = props => {
    const [accountInfo,setAccountInfo] = useState(props.oldValue || {});
    const globalInfo = props.globalInfo || {};
    

    const changeText = (e,value = null,name = null) => {
        e.preventDefault();
        setAccountInfo({
            ...accountInfo,
            [name || e.target.name] : value || e.target.value  
        });
        accountInfo[name || e.target.name] = value || e.target.value;
        setInfo(accountInfo)
    }

    const setInfo = (info) => {
        props.setFormInfo({...globalInfo,accountInfo : info});
    }

    return <Fragment>
    <div className='my-2'>
       <h5>MOYEN DE PAIEMENT</h5> 
    </div>
    <div className="amount row">
        <div className="col-12 mb-3">
            <Autocomplete
                options={props.paymentWays || []}
                sx={{ width: '100%' }}
                renderInput={(params) => <TextField error={props?.errors?.find(error => error.name === "paymentWay")?.error}
                helperText={props?.errors?.find(error => error.name === "paymentWay")?.error || null} fullWidth {...params} label="Moyen de paiement" />}
                name = "paymentWay"
                onChange={(e,value) => {changeText(e,value,"paymentWay")}}
                value={accountInfo.paymentWay}
                error={props?.errors?.find(error => error.name === "paymentWay")?.error}
                helperText={props?.errors?.find(error => error.name === "paymentWay")?.error || null}
            />
        </div>
        <div className="col-12  my-3">
            <TextField
                fullWidth
                id="clientAccountNumber"
                label="Numero de compte client"
                name = "clientAccountNumber"
                onChange={changeText}
                value={accountInfo.clientAccountNumber}
                onBlur={(e) =>  setInfo(accountInfo)}
                error={props?.errors?.find(error => error.name === "clientAccountNumber")?.error}
                helperText={props?.errors?.find(error => error.name === "clientAccountNumber")?.error || null}
            />
        </div>
        <div className="col-12  my-3">
            <TextField
                fullWidth
                id="promotionCode"
                label="Code promotion"
                name='promotionCode'
                onChange={changeText}
                value={accountInfo.promotionCode}
                onBlur={(e) =>  setInfo(accountInfo)}
            />
        </div>
    </div>
    <hr />
          
</Fragment>
}

export default AccountInfo; 