import React from "react";
import "./Values.css";
import Title from "../../../../vendor/template/front/_layouts/Title";


const valuesList = [
  {
    icon : 'shield',
    iconColor : 'primary',
    title : 'Fiabilité',
    text : ' Nous accordons la priorité à la sécurité et à la fiabilité de '+
            'chaque transaction, veillant à ce que les fonds parviennent ' + 
            'rapidement et en toute sécurité à leurs destinataires prévus.'
  },
  {
    icon : 'thumbs-up',
    iconColor : 'purple',
    title : 'Simplicité',
    text :  'Nous nous efforçons de rendre le processus de transfert d\'argent '+
            'aussi simple et convivial que possible, en éliminant les '+
            'complexités et les obstacles inutiles.'
  },
  {
    icon : 'check-circle',
    iconColor : 'red',
    title : 'Transparence',
    text :  'Nous croyons en une tarification transparente, fournissant des '+
            'informations claires et préalables sur les frais, les taux de '+
            'change et tout autre frais pertinent.'
  },
  {
    icon : 'tachometer',
    iconColor : 'green',
    title : 'Rapidité',
    text :  'Nous accordons la priorité au transfert rapide et efficace des '+
            'fonds, comprenant l\'importance des transactions en temps opportun '+
            'pour nos clients.'
  },
  {
    icon : 'street-view',
    iconColor : 'yellow',
    title : 'Orientation client',
    text :  'Les besoins et la satisfaction de nos clients sont au cœur de '+
            'toutes nos actions. Nous nous engageons à offrir un service '+
            'exceptionnel et un support à chaque étape du parcours.'
  }
] 


const Values = () => {
  return (
    <div className="values">
      <div className="main p-5">
        <Title
          title={"Nos valeurs"}
        />
        
        <div className="row">
          
           {
            valuesList.map(
              value => <SingleValue key={value.title} value={value}/>
            )
           }
        </div>
      </div>
    </div>
  );
};


const SingleValue = ({value}) => {
  return <div className="col-12 col-lg-4 col-xl-4 col-md-6 p-2 mb-4">
            <div className="card border-0 shadow rounded-xs pt-5">
                <div className="card-body"> 
                    <span className={`"icon-bg-circle  icon-bg-${value.iconColor} text-center  mb-2"`}>
                      <i className={`fa fa-${value.icon}  icon-lg icon-${value.iconColor} `}> </i>
                    </span>
                    
                    <h4 className="mt-4 mb-3">{value.title}</h4>
                    <p> {value.text} </p>
                </div>
            </div>
          </div>
}


export default Values;
