import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const promotionsConfiguration = {
  name: 'promotions',
  title: 'Codes promotions',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom promotion',
      col: 4,
    },
    {
      name: 'code',
      title: 'Code promotion',
    },
    {
      name: 'calculWay',
      title: 'Mode de calcul',
      htmlElement: 'select',
      col: 4,
      options: [
        {
          label : 'Fixe',
          value : 'Fixe'
        },
        {
          label : 'Pourcentage',
          value : 'Pourcentage'
        }
      ]
    },
    {
      name: 'exonerationTaxe',
      title: 'Taux exonération',
      col: 4,
      type: 'number',
    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: ['state','code'],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (promotionsConfiguration);
