import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class CurrenciesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'currencies',
      path: 'currencies',
    });
  }

  async valid (id) {
    return await this.controller.active(id);
  }

  async cancel (id) {
    return await this.controller.disable(id);
  }
}
