import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const recipientsConfiguration = {
  name: 'recipients',
  title: 'Bénéficiaire(s)',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom du bénéficiaire',
      col: '6',
    },
    {
      name: 'accountNumber',
      title: 'N° de compte',
      col: '6',
    },
    {
      name: 'bank',
      title: 'Banque',
      htmlElement: 'select',
      type: 'objectFetcher',
      col: '6',
      options: [],
      typeValueSent : 'object',
      dataServiceSource: 'banks',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'banks',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'currency',
      title: 'Devise',
      htmlElement: 'select',
      type: 'objectFetcher',
      col: '6',
      options: [],
      typeValueSent : 'object',
      dataServiceSource: 'currencies',
      isObject: true,
      fieldsSelected: ['name'],
      selectDataValue: {
        name: 'currencies',
        options: {
          valueOptions: ['id'],
          labelOptions: ['name'],
        },
      },
    },
    {
      name: 'email',
      type: 'email',
      col: '4',
    },
    {
      name: 'tel',
      title: 'Téléphone',
      col: '4',
      type: 'phone',
    },
    {
      name: 'address',
      title: 'Adresse',
      col: '4',
    },
  ],
  excludeToForms: [],
  excludeToHeaders: [],
  filterOptions: [],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (recipientsConfiguration);
