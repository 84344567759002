import config from './config.json';
import mode from './envFile';





const apiConfig = {
  api: mode === 'prod'
    ? 'https://api.well-transfer.com'
    : `${config.API_URL}:${config.API_PORT}`,
  appName: config.APP_NAME,
  apiPublicRoutes: ['user/login'],
  baseUrl: mode === 'prod' ? "https://well-transfert.com" : mode === 'test' ? "https://test.well-transfert.com" : 'http://localhost:3000'
};

export default apiConfig;
