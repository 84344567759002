import dataFormatter from '../responses/row-formatter.response';
import DefaultController from './default.controller';

export default class CurrenciesController extends DefaultController {
  rules = [{title: 'name', rules: ['required', 'unique:currencies:name']}];

  constructor () {
    super ({
      path: 'currencies',
    });
  }

  async otherTreatCredentials (credentials){
    return {
      ...credentials,
      state : 1
    };
  }

  async active(id){

    const response = await this.readFirebase (
      `${('currencies' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 1
    };


    return this.update(id,data)
  }


  async disable(id){
    const response = await this.readFirebase (
      `${('currencies' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 0
    };
    return this.update(id,data)
  }
}
