import {createTheme, ThemeProvider} from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from '../../../assets/images/logo.png';
import './register.css';
import {useState} from 'react';
import Button from '@mui/material/Button';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import {
  IconButton,
  InputAdornment, 
  TextField,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

const defaultTheme = createTheme ();

export default function RegisterRender (props) {

  const [registerFormValues,setRegisterFormValues] = useState({});

 // const [registerWay,setRegisterWay] = useState('email');
  const registerWay = 'email';

  const handleFormChanges = (e,name = null, value = null) => {
    setRegisterFormValues({
      ...registerFormValues,
      [name || e.target.name] : value || e.target.value
    })
  }
  
  const theme = useTheme();
  //const [value, setValue] = React.useState(0);
  const value = 0;

  /*const handleChange = (event, newValue) => {
    setValue(newValue);
    const value = {
      registerWay : newValue === 0 ? 'email' : 'phone'  
    };
    setRegisterWay(value.registerWay);
    setRegisterFormValues(value)
  };*/

  const onSubmitForm = (e) => {
    e.preventDefault();
    props.frameConfigs.onSubmitForm(e,
      {
        ...props.dialogInformation?.dialogContent?.fieldsData,
        registerWay : {value : registerWay}
      }
    )
  }


  const [showPassword, setShowPassword] = useState (false);

  const handleClickShowPassword = () =>
    setShowPassword (showPassword => !showPassword);

  const handleMouseDownPassword = event => {
    event.preventDefault ();
  };

  return (
    <div className='login-body bg-light'>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box
          sx={{
            marginY: 0,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <div className="form-container col-12 col-md-8 col-xl-8 col-lg-8">
           
            <Box
              component="form"
              onSubmit={onSubmitForm}
              noValidate
              sx={{my: 2}}
            >
                <div className="form-icon">
                  <img
                    className="rounded-circle logo"
                    alt="user-avatar"
                    src={logo}
                  />
                </div>
                  

              <div className="form-card my-2 col-12">
                <Typography component="h4" sx={{color: 'white',textAlign:'center',bgcolor:'gray',padding:2, marginBottom:1 }} variant="h5">
                  ESPACE INSCRIPTION
                </Typography>
              
              
                <TabPanel value={value} index={0} dir={theme.direction}>
                    
                      <TextField
                        error={props?.dialogInformation?.dialogContent?.fieldsData?.email?.errors ? true : false}
                        sx={{ my:2 }}
                        required
                        label="Email"
                        fullWidth
                        name="email"
                        onChange={handleFormChanges}
                        onBlur={props.frameConfigs?.handleFormField}
                        autoComplete="false"
                        value={registerFormValues?.email|| ""}
                        helperText={props.dialogInformation?.dialogContent?.fieldsData?.email?.errors || null}
                      />

                      <TextField
                          error={props.dialogInformation?.dialogContent?.fieldsData?.password?.errors ? true : false}    
                          required
                          sx={{ my:2 }}
                          autoComplete="false"
                          onChange={handleFormChanges}
                          onBlur={props.frameConfigs?.handleFormField}
                          id="password"
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          value={registerFormValues?.password || ""}
                          helperText={props.dialogInformation?.dialogContent?.fieldsData?.password?.errors || null}
                          InputProps={{
                            endAdornment:   <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>,
                          }}
                          fullWidth
                          label="Definir mot de passe"
                        />
                     <TextField
                          error={props.dialogInformation?.dialogContent?.fieldsData?.password?.errors ? true : false}    
                          required
                          autoComplete="false"
                          onChange={handleFormChanges}
                          onBlur={props.frameConfigs?.handleFormField}
                          id="confirmPassword"
                          type={showPassword ? 'text' : 'password'}
                          name="confirmPassword"
                          value={registerFormValues?.confirmPassword ||  ""}
                          helperText={props.dialogInformation?.dialogContent?.fieldsData?.password?.errors || null}
                          InputProps={{
                            endAdornment:   <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>,
                          }}
                          fullWidth
                          label="Confirmer mot de passe"
                        />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
                <TextField
                  error={props.dialogInformation?.dialogContent?.fieldsData?.username?.errors ? true : false}
                  required
                  label="Telephone"
                  fullWidth
                  name="tel"
                  onChange={props.frameConfigs?.handleFormField}
                  autoComplete="false"
                  value={props.frameConfigs?.dialogInformation?.dialogContent?.fieldsData?.username?.value || ""}
                  helperText={props?.dialogInformation?.dialogContent?.fieldsData?.username?.errors || null}
                />            
            </TabPanel>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    my: 1,
                    backgroundColor: '#000039',
                    borderColor: '#000039',
                    color: '#fff',
                    p: 2,
                  }}
                >
                  ENREGISTRER
                </Button>

                <p className="sign_up">
                          Deja un compte ? 
                          <NavLink to="/login"> Se connecter</NavLink>
                 </p>
              </div>

            </Box>

          </div>

        </Box>
      </Container>
    </ThemeProvider>
    </div>
  );
}



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

/*function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}*/
