export default class DefaultFirebaseQuery{
    rules = [];
    name = "";
    
    constructor (params = null) {
        this.name = params?.name;
        this.path = params?.path;
        this.controller = params.controller || this.loadControllerByName(); 
    }
    

    loadControllerByName () {
        const controller = require (`../../../back-end/controllers/${this.name?.toLowerCase ()}.controller`)
          .default;
        return new controller ();
    }


    async loadList (){
        return await this.controller.readAll();
    }

    async create (credentials) {
        return await this.controller.create(credentials);
    }
    
    async readAll (params = null) {
        return await this.controller.readAllWithSpecification(params);
    }
    
    async readOne (id) {
        return await this.controller.readOne (id);
    }

    async update (id, payLoad) {
        console.log(payLoad)
        return await this.controller.update(id,payLoad);
    }
    
    async delete (id) {
        return await this.controller.delete(id);

    }
    
}