import React from "react";
import '../../../../assets/site/css/styles.css';
import Load from '../../../../assets/site/img/Loading.gif';



export const Loading = () => {
  return (
    <div className="loadImage">
      <img src={Load} alt="load_image" />
    </div>
  );
};

export default React.memo(Loading);