import BaseFrontComponent from "../base-front-component";

export default class HomeComponent extends BaseFrontComponent{
    name = 'home'

    options = [
        {
          title: 'changetaxes',
          keys: ['name'],
        },
        {
            title: 'currencies',
            keys: ['symbol'],
            imgTarget:"flag"
        },
    ];

    state = {
        ...this.state
    }

    componentDidMount(){
        this.loadOptions();
    }
}