import React from "react";
import Title from "../../../vendor/template/front/_layouts/Title";
import AboutImage from "../../../assets/site/img/whoweare.png";
import "./AboutUs.css";
import TeamSection from "../sections/TeamSection/TeamSection";
import Questions from "../sections/Questions/Questions";


const HelpAndSupport = () => {
  return (
    <div className="aboutus">
      <div className="main p-5">
        <Title
          title="Aide & support"
          subtitle="Well Transfer est un fournisseur de services de paiement de premier plan qui aide les particuliers à envoyer facilement de l'argent en Russie."
        />
      </div>
      <div className="aboutImage">
        <img src={AboutImage} alt="who we are" />
      </div>
      <TeamSection />
      <Questions />
      
    </div>
  );
};

export default HelpAndSupport;