import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class FeesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'fees',
      path: 'fees',
    });
  }

  async valid (id) {
    return await this.controller.activeFee(id);
  }

  async cancel (id) {
    return await this.controller.disableFee(id);
  }

}
