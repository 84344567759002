import {
  BaseConfiguration,
} from './../../../vendor/base-component-class/config.class';

const paramsConfiguration = {
  name: 'params',
  title: 'Parametre(s) de base application',
  typeFrame: 'list',
  fields: [
    {
      name: 'name',
      title: 'Nom',
      col:6
    },
    {
      name: 'value',
      title: 'Valeur',
      col:6
    },
    {
      name: 'state',
      title: 'Statut',
      options: {
        0: {badgeColor: '#DC143C', text: 'Inactif', textColor: 'white'},
        1: {badgeColor: '#5F9EA0', text: 'Actif'},
      },
      type: 'checkBox',
    },
  ],
  excludeToForms: ['state'],
  excludeToHeaders: [],
  filterOptions: [],
  actions: [
    {
      name: 'edit',
      title: 'Modifier frais',
      color: 'success',
      icon: 'pen',
    },
  ],
  dialogsConfig: {
    formDialog: {
      size: 'md',
    },
    singleDialog: {
      size: 'md',
    },
  },
};

export default BaseConfiguration (paramsConfiguration);
