import React, { useEffect, useState } from "react";
import "./Blog.css";
import { useParams } from "react-router-dom";
import { client } from "../../../configs/sanity";
import { fetchPostDetail} from "../../../configs/utils/data";

import moment from "moment";
import BlockContent from "@sanity/block-content-to-react";

const BlogDetails = () => {
  const [postDetails, setPostDetails] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchPostDetail(slug)).then((data) => {
        setPostDetails(data[0]);
      });
    };
    fetchData();
  }, [slug]);

  return (
    <div className="blog-details">
      <div className="main p-5">
        <div className="blog-heading">
          <p>Published on {moment(postDetails?.date).format("MMMM DD YYYY")}</p>
          <h1>{postDetails?.title}</h1>
          <h3>{postDetails?.subtitle}</h3>
          <div className="categories">
            {postDetails?.categories?.map((category, index) => (
              <p key={index}>{category.title}</p>
            ))}
          </div>
          <img
            src={`${postDetails?.coverImage?.asset.url}`}
            alt={postDetails?.title}
          />
        </div>
        <div className="text_content">
          <BlockContent
            blocks={postDetails?.content}
            projectId={`1e1q56v9`}
            dataset="production"
          />
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
