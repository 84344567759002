import dataFormatter from '../responses/row-formatter.response';
import DefaultController from './default.controller';
//import { sendEmail } from 'resend';

export default class TransactionsController extends DefaultController {
  constructor () {
    super ({
      path: 'transactions',
    });
  }

  

  async otherTreatCredentials (credentials){

    /*sendEmail(
      {
        to: 'welltransfer@outlook.com',
        subject: 'notification de transaction',
        from: 'moloundoua@gmail.com',
        html: "Une nouvelle transaction ajoute",
      }
    )*/

    const codeTransaction = await this.generateTransactionNumber();
    return {
      ...credentials,
      transactionNumber : codeTransaction,
      dateTransaction: new Date(),
      state : 0
    };
  }

  async transactionByClient (id) {
    return await this.findByClient (id);
  }

  async valid (id) {
    const response = await this.readFirebase (
      `${('transactions' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 1
    };


    return this.update(id,data)
  }

  async cancel (id) {
    const response = await this.readFirebase (
      `${('transactions' || this.path) + '/' + id}`
    );

    const data = {
      ...dataFormatter.SingleFormatterResponse (response.data),
      state : 2
    };
    return this.update(id,data)
  }

  async findByClient(clientId){
    const transactionsQuery = await this.readAll ();
    return {success: true, data: transactionsQuery.data.filter (transaction => transaction?.sender?.id === clientId),message : 'succes recuperation transaction'};

  }

  async generateTransactionNumber(){
    let transactionNumber = Math.floor(Math.random() * (999999 - 100000) + 100000);
    
    const codeChecking = await this.checkTransactionNumber(transactionNumber);

    if(codeChecking){
      transactionNumber = await this.generateTransactionNumber()    
    }

    return transactionNumber;
  }

  async checkTransactionNumber(code){
    const response = await this.readFirebase (
        `${('transactions' || this.path)}`
    );

    const list = dataFormatter.ListFormatterResponse(response.data);

    const transactionByNumber = list.findIndex(transaction => transaction.transactionnumber === code);

    return transactionByNumber !== -1;
  }
}
