import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class ClientsQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'clients',
      path: 'clients',
    });
  }

  async getCurrentClientProfile (){
    return await this.controller.getCurrentClientProfile();
  }

  async findClientAffiliates (id) {
    return await this.controller.findClientAffiliates (id);
  }
}
