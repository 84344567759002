import LoggedUserModel from '../models/login.model';
import DefaultController from './default.controller';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
 // deleteUser,
} from 'firebase/auth';
import RolesController from './roles.controller';
import { signInWithPopup } from 'firebase/auth';
import { appAuthProvider,appAuthGoogleProvider } from '../../root/external-communication/firebase.providers';

export default class UserController extends DefaultController {
  roleController = new RolesController ();

  constructor () {
    super ({
      path: 'users',
    });
  }

  loginErrors = [];

  login = async credentials => {
      if(credentials.signInType === 'withGoogle'){
        return await this.signInWithGoogle();
      }else if(credentials.signInType === 'withCredentials'){
        return await this.loginWithCredentials(credentials);
      }
  };

  async loginWithCredentials(credentials){
    this.checkLoginRules (credentials, [
      {title: 'username', rules: ['required']},
      {title: 'password', rules: ['required'],},
    ]);

    if (this.loginErrors.length > 0) {
      return {
        data: null,
        errors: this.loginErrors,
        message: 'Entrées invalides',
        success: false,
      };
    }

    const auth = getAuth ();
    let data = null;

    let response = {data: data, errors: [], message: null, success: false};

    try {
      data = await signInWithEmailAndPassword (
        auth,
        credentials.username,
        credentials.password
      ).catch((ex) => {
        return {
          message : ex,
          loginFail: true 
        }
      });

      if(data.loginFail){
        let message = null;

        switch(response.message.code){
          case 'auth/invalid-credential' : 
              message = "Login ou le mot de passe est incorrecte";
            break;

          default: 
              message = response.message.code;
              break;
        }

        response.success = false;
        response.message = message;
        response.data = null;
        response.errors = [];

      }else{
        response.success = true;
        response.message = 'Utilisateur authentifier avec succès';
        const authUser = new LoggedUserModel ();
        authUser.isLoggedIn = true;
        authUser.user = await this.format (data.user);
        response.data = authUser;
      }

    } catch (exception) {
      response.success = false;
      response.message = 'Utilisateur introuvable dans la base de données';
      response.data = null;
      response.errors = [];
    }
    return response;
  }

  async format (user) {
    let userFound = await this.findByEmail (user.email);

    userFound.authorities = this.getAuthorities (userFound.roles);
    return userFound;
  }

  async getAuthorities (roles) {
    /*const rFound = roles.map (async role => {
      const roleFormat = await this.roleController.readOne (role);
      return roleFormat?.data;
    });*/
    //console.log('roles', await rFound)
    return '';
  }

  checkLoginRules (credentials, rules) {
    this.rulesChecker (this.loginErrors, credentials, rules);
  }

  checkRegisterRules (credentials, rules) {
    this.rulesChecker (this.registerErrors, credentials, rules);
  }

  registerErrors = [];

  register = async credentials => {
    if(credentials.registerWay === 'email'){
      return await this.registerByEmail(credentials)
    }else{
      return await this.registerByPhone(credentials)
    }
  };

  async registerByEmail(credentials){
    this.checkRegisterRules (credentials, [
      {title: 'email', rules: ['email','required']},
      {title: 'password', rules: ['required','confirm','minText:6']},
    ]);
   
    if (this.registerErrors.length > 0) {
      return {
        data: null,
        errors: this.registerErrors,
        message: 'Entrées invalides',
        success: false,
      };
    }

      const auth = getAuth ();
      const  response = await createUserWithEmailAndPassword (
        auth,
        credentials.email,
        credentials.password
      ).catch((ex) => {
        return {
          message : ex,
          success: false
        }
      });

      if(response.operationType === 'signIn' && response.user){
        delete credentials.confirmPassword;
        delete credentials.password;
        return await this.create (credentials);
      }

      let message = null;

      switch(response.message?.code){
        case 'auth/email-already-in-use' : 
            message = "Cet adresse email a deja ete utilise";
          break;
        case 'auth/weak-password' : 
          message = "Le mot de passe doit contenir au moins 6 caractere";

          break;
        default: 
            message = response.message?.code;
            break;
      }

      return {
        message : message,
        success : false,
        data : null,
        errors: []
      }
  }

  async registerByPhone(credentials){
    this.checkRegisterRules (credentials, [
      {title: 'phone', rules: ['required']},
    ]);
   
    if (this.registerErrors.length > 0) {
      return {
        data: null,
        errors: this.registerErrors,
        message: 'Entrées invalides',
        success: false,
      };
    }


    return {
      data: null,
      errors: [],
      message: 'test',
      success: false,
    };
  }

  signInWithGoogle = async () => {
    let data = null;

    let response = {data: data, errors: [], message: null, success: false};

    try {
      data = await signInWithPopup(appAuthProvider,appAuthGoogleProvider);
      response.success = true;
      response.message = 'Utilisateur authentifier avec succès';
      const authUser = new LoggedUserModel ();
      authUser.isLoggedIn = true;
      authUser.user = await this.format (data.user);
      response.data = authUser;
    } catch (exception) {
      response.success = false;
      response.message = 'Utilisateur introuvable dans la base de données';
      response.data = null;
      response.errors = [];
    }
    return response;
  }

  /*delete = email => {
    deleteUser()
  }*/

  async findByEmail (email) {
    const usersQuery = await this.readAll ();
    return usersQuery.data.find (user => user.email === email);
  }
}
