import React, { useState, useEffect } from "react";
import "./Blog.css";
import { client, urlFor } from "../../../configs/sanity";
import { fetchBlog, searchQuery } from "../../../configs/utils/data";
import { NavLink } from "react-router-dom";
import Title from "../../../vendor/template/front/_layouts/Title";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchBlog).then((data) => {
        setPosts(data);
      });
    };
    fetchData();
  }, []);

  const HandleChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    if (searchText === "") {
      client.fetch(fetchBlog).then((data) => {
        setPosts(data);
      });
    } else {
      client.fetch(searchQuery(e.target.value)).then((data) => {
        setPosts(data);
      });
    }
  };

  return (
    <div className="blog">
      <div className="main p-5">
        <Title title={"Notre blog"} subtitle={"Les écrits de notre équipe!"} />
        <div className="search">
          <input
            type="text"
            placeholder="Rechercher article..."
            onChange={(e) => HandleChange(e)}
            value={searchText}
          />
        </div>
        <div className="posts row">
            {posts?.map((post) => (
              <SingleReviewCard key={post?._id} post={post}/>
            ))}
          </div>
      </div>
    </div>
  );
};


function SingleReviewCard({post}) {

  return (
    <Card sx={{ maxWidth: 400, margin: '10px'  }}>
      <CardHeader
        
        title={`${post?.title.slice(0, 70)}...`}
      />
      <CardMedia
        component="img"
        height="194"
        image={urlFor(post?.coverImage)}
        alt={post?.title}
      />
      <CardContent>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {post?.description.slice(0, 250)}...
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <NavLink className='btn btn-sm btn-outline-dark' to={`/blog/${post?.slug.current}`}>
          Lire plus
          <i className="fa fa-arrow-right mx-2"></i>

        </NavLink>
      </CardActions>
    </Card>
  );
}

export default Blog;
