import React, { Fragment } from 'react';
import { capitalizeFirstLetter, checkConditions } from './../../../bootstrap/Helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

import avatar from "./../../../assets/images/avatar.png";
import image from "./../../../assets/images/img.jpg";
import moment from 'moment';
import appParams from '../../../configs/app-params';
//import { useSelector } from 'react-redux';

export default function TableBuilder (props) {
    const headers = props.information?.dataColumns; 
    const actions = props.information?.actions?.actions || props.information?.actions;

    return (
        <table className="table table-striped table-hover table-table-responsive" width="100%">
            <THead headers={headers} actionsLength ={actions?.length || 0} />
            <TBody featureName={props.featureName} actions={actions || []} data={props.information?.items ?? []} headers={headers} openDialog={props.openDialog}/>
            {/*<TFoot headers={headers} />*/}
        </table>
    );
}

const THead = props => {
  return (
    <thead>
      <tr>
       <th scope="id">#</th>
       {
        props.headers?.map(
            header => <th scope={header.title} key={header.title}>
                {capitalizeFirstLetter(header.title)}
            </th>
        )
       }
       {
        props.actionsLength > 0 ?  <th scope="action">ACTIONS</th> : null
       }      
      </tr>
    </thead>
  );
}

const TBody = props => {
    return (
    <tbody>
        {
            props.data?.length > 0 ?  props.data.map(
                (item,index) => {
                   // console.log("row : ",item.currency1)
                    return <TableRowsBuilder 
                                    dataId={item.id} 
                                    index={index +1} 
                                    key={item.id} 
                                    actionsProperties={props.actions} 
                                    providedItem={item} 
                                    dataColumns={props.headers}
                                    openDialog={props.openDialog}
                                    featureName={props.featureName}
                            />
                }
            ) : 
            <tr>
                <td className="text-center" colSpan={props.headers.length + 2}> Aucune ligne trouvée</td>
            </tr>
        }
        
      
    </tbody>
  );
}


const TableRowsBuilder = props => {

    const item = props.providedItem;

    const columns = props.dataColumns;
    const actionsProperties = {
        actions : props.actionsProperties,
        dataId: props.dataId,
        data : item,
        featureName : props.featureName
    };
    return  <tr>
                <td>{props.index ?? 1}</td>
                {
                columns?.map(
                    (column,index) => {
                        return <td className="p-2" key={item?.id + column?.title + index}>
                                    <TableCellRender key={column} column={column} value = {item} />
                                </td>
                    }
                )
              }
            {
                actionsProperties?.actions?.length > 0 ? <td>
                {BuildTableAction(actionsProperties,props.openDialog)}
            </td>   : null
            }  
           
            </tr>
}



class TableCellRender extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            value : null,
            columnProperties : {},
            cellRender : DefaultCellRender    
        }
        this.getCellRender = this.getCellRender.bind(this); 
    }
    

    async componentDidMount(){
        await this.setState({
            value : this.props.value?.[this.props.column?.name],
            columnProperties : this.props.column,
            cellRender : await this.getCellRender(this.props.column?.type?.toLowerCase())
        });
    }

    async componentDidUpdate(prevProps,prevState){
        if(this.props !== prevProps){

            await this.setState({
                value : this.props.value?.[this.props.column?.name]
            });
            if(this.props.column !== prevProps.column){
                await this.setState({
                    columnProperties : this.props.column,
                    cellRender : await this.getCellRender(this.props.column?.type?.toLowerCase())
                });
            }  
        }
    }

    getCellRender(type){
        switch(type){
            case "objectfetcher" : return ObjectRender;
            case 'date' : return DateRender;
            case 'datetime' : return DateTimeRender;
            case 'number': return NumberRender;
            case 'image': return ImageRender;
            case 'user-avatar' :  return AvatarRender;
            case 'state-multiple' : return MultipleStateRender;
            case 'checkbox' : return CheckboxRender;
            case 'money': return MoneyRender;
            default: return DefaultCellRender;
        }
    }
    
    
    render(){
        const CellRender = this.state.cellRender || DefaultCellRender;
        return <CellRender properties={this.state.columnProperties} item={this.props.value} value={this.state.value} />
    }
}


const ObjectRender = (props) => {
    let text = "";
    return <span>
                {
                    props.properties?.fields?.forEach(
                        field => {
                            text = text+" "+props.value?.[field];
                        }
                    )
                }
            {text}
        </span>
}

const DateRender = props => {
    return <span>
                {
                    props.value ?
                    moment(props.value).format("DD/MM/YYYY") : null
                }
            </span>
}

const DateTimeRender = props => {
    return <span>
                {
                    props.value ?
                    moment(props.value).format("DD/MM/YYYY hh:mm:ss") : null
                }
            </span>
}

const NumberRender = props => {
    let val = 0; 
                                                
    if(props.value && props.value !== "NaN"){
       val =  props.value;
    }

    return <span>
                {val?.toLocaleString("fr-FR")}
            </span>
}

const ImageRender = props => {
    
    const img = props.value ?? image; 

    return <img 
                src={img} 
                className="img img-thumbnail" 
                alt="myImg" 
                style={{ height : '50px',
                        width: "50px" }} 
            />
}

const AvatarRender = props => {
    const imgPath = props.value ?? avatar;
    return <Avatar
                alt="avatar"
                src={imgPath}
                sx={{ width: 30, height: 30 }}
            />
}

const MultipleStateRender = props => {
    return <Chip 
                sx={
                    { 
                        p:1,
                        bgcolor : `${ props.properties?.options[props.value]?.badgeColor}`,
                        color : `${ props.properties?.options[props.value]?.textColor ?? 'white'}` 
                    }
                }
                label={`${ props.properties?.options[props.value]?.text}`} 
                variant="outlined" 
            />
}

const CheckboxRender = props => {
    const i = props.value ? 1 : 0;

    return <Chip 
                sx={
                    { 
                        p:1,
                        bgcolor : `${ props.properties?.options[i]?.badgeColor}`,
                        color : `${ props.properties?.options[i]?.textColor ?? 'white'}` 
                    }
                }
                label={`${ props.properties?.options[i]?.text}`} 
                variant="outlined" 
            />
}

const MoneyRender = props => {
    return <span>
                {
                    Intl.NumberFormat(props.properties?.devise || appParams.language,{ style: 'currency',
                        currency: props.properties?.currency || appParams.currency,
                        
                            }).format(props?.value)    
                }
            </span>
}

const DefaultCellRender = (props) => {
    return <span>{props.value?.toLocaleString("fr-FR")}</span>
}

/*const TFoot = props => {
  return (
    <tfoot>
      <tr>
       <th scope="id">#</th>
       {
        props.headers?.map(
            header => <th scope={header.title} key={header.title}>
                {capitalizeFirstLetter(header.title)}
            </th>
        )
       }
       <th scope="action">ACTIONS</th>
      </tr>
    </tfoot>
  );
}*/


export function BuildTableAction(actionsData,openDialog){
   // const currentUser = useSelector(state => state.auth);

    return <Fragment>
            {
            actionsData?.actions?.map(
                action => {
                    /*if(!currentUser?.user?.authorities?.includes(actionsData?.featureName+":"+action?.name)){
                        return null;
                    }*/

                    const actionClass = "btn btn-sm my-sm-1 btn-"+action.color;
                    let displayBtn = true;
                    if(action?.elementTest && action?.elementTest !== ""){
                        displayBtn = actionsData?.data?.[action?.elementTest] === action.value;
                        if(action?.operator && action?.operator === "different"){
                            displayBtn = actionsData?.data?.[action?.elementTest] !== action.value;
                        }
                    }

                    if(action?.renderConditions && action?.renderConditions.length > 0){
                        let tests = [];

                        action?.renderConditions.forEach(
                            condition => {
                                let test = actionsData?.data?.[condition?.field] === condition?.value;
                                
                                if(condition?.operation === "different"){
                                    test = actionsData?.data?.[condition?.field] !== condition?.value;
                                }
                                tests.push(test);
                            }
                        );


                        
                       tests.forEach(
                            (test,index) => {
                                let operation = action?.renderConditions[index]?.nextConditionConnector;
                                

                                if(index > 0 && index < tests.length - 1){
                                    if(operation){
                                        if(checkConditions(tests[index-1],operation,tests[index])) {
                                            displayBtn = false;
                                        }             
                                    }
                                }else{
                                    if(test){
                                        displayBtn = false;
                                    }
                                }
                            }
                        )
                        
                       
                    }
                    return  <Fragment key={action?.name}>
                                {
                                    displayBtn ?  <button 
                                    className={actionClass + " mx-1 p-1 px-2 "}
                                    onClick={(e) => {openDialog(e,action?.name,actionsData.dataId)}}
                                    name={action?.name}
                                    id={actionsData.dataId}
                                    title={action?.title}
                                >
                                    <FontAwesomeIcon icon={action?.icon}/>
                                </button> : null
                                }
                               
                            </Fragment>
                }
            )
        }
    </Fragment>
}