import React, { useEffect, useState } from 'react';
import './style.css'
import PersonalInfo from './_layouts/personal-info';
import AmountSentFormInfo from './_layouts/amount-sent-info';
import AccountInfo from './_layouts/account-info';
import TransactionsService from '../../../external-communication/services/transactions.service';
import ClientsService from '../../../external-communication/services/clients.service';
import { Navigate } from 'react-router-dom';



export default function TransactionFormRender(props){
    const [formInfo,setFormInfo] = useState({});
    const [formError,setFormError] = useState([]);
    const [client,setClient] = useState({});
    const [reload,setReload] = useState(true);

    const getCurrentClientQuery =  async () => {
       const clientQuery  = await new ClientsService ().getCurrentClientProfile();
       if(clientQuery.success){
            await setClient(clientQuery.data);
            setReload(false);
        }
    };
    
    useEffect(
        () => {
            
            if(reload){
                getCurrentClientQuery();
            }
        }
    )


    const taxes = props?.frameConfigs?.options?.find(opt => opt.title === "changetaxes")?.options || []; 
    const paymentWays = props?.frameConfigs?.options?.find(opt => opt.title === "paymentways")?.options || [];
    const currencies = props?.frameConfigs?.options?.find(opt => opt.title === "currencies")?.options || [];

    const [activeStep,setActiveStep] = useState(0);
    
    

    const changeFormValue = info => {
        setFormInfo(info);   
    }

    const changeActiveStep = (number) => {
        setActiveStep(number);
        checkFormValidator(number)
    }

    const goToNext = () => {
        if(activeStep !== 2){
            const errors = checkFormValidator(activeStep);

            if(errors.length <= 0){
                const step  = activeStep + 1;
                changeActiveStep(step);
                setFormError([]);
            }else{
                setFormError(errors);
            }
        }
    }

    const checkFormValidator =  number => {
        let errors = []

        if(number === 0){
            
            if(!formInfo?.personalInfo?.recipientName || formInfo?.personalInfo?.recipientName === ""){
                errors.push({name : 'recipientName',error : "Le champ nom beneficiaire est requis"});
            }

            if(!formInfo?.personalInfo?.recipientContact || formInfo?.personalInfo?.recipientContact === ""){
                errors.push({name : 'recipientContact',error : "Le champ contact beneficiaire est requis"});
            }
            
            setFormError(errors);

        }else if(number === 1){
            if(!formInfo?.amountSentInfo?.sentAmount || formInfo?.amountSentInfo?.sentAmount === ""){
                errors.push({name : 'sentAmount',error : "Le montant envoye est requis"});
            }

            if(!formInfo?.amountSentInfo?.taxe || formInfo?.amountSentInfo?.taxe === ""){
                errors.push({name : 'taxe',error : "Verifier les devises d'envoie et reception svp"});
            }
            
            setFormError(errors);
        }
        else if(number === 2){
            if(!formInfo?.accountInfo?.paymentWay || formInfo?.accountInfo?.paymentWay === ""){
                errors.push({name : 'paymentWay',error : "Le moyen de paiement est requis"});
            }

            if(!formInfo?.accountInfo?.clientAccountNumber || formInfo?.accountInfo?.clientAccountNumber === ""){
                errors.push({name : 'clientAccountNumber',error : "Le numero de compte client est requis"});
            }
            
            setFormError(errors);
        }
        return errors;
    }

    const goToPrev = () => {
        if(activeStep !== 0){
            const step  = activeStep - 1;
            changeActiveStep(step);
        }
    }

    const registerTransaction = async () => {
        let errors = [];
        
        errors = checkFormValidator(2);

        if(errors.length <= 0){
            
            let data = {
                ...formInfo.personalInfo,
                ...formInfo.amountSentInfo,
                ...formInfo.accountInfo,
            };


            let payload = {};

            for(let line in data){
                payload = {
                    ...payload,
                    [line]: {value : data[line]}
                }
            }

           payload.sender = {value : client};

           payload.paymentWay = {value : payload.paymentWay?.value?.originalObject};

           const query = await new TransactionsService().create(payload);

           console.log("result : ",query)

           if(query.success){
            return <Navigate to="client-profile"/>
           }

        }else{
            setFormError(errors);
        }
        
    }

    return <div className='d-flex justify-content-center my-5'>
                <div className="form-group card col-12 col-md-6 col-lg-6  col-xl-6 p-3">
                    <div className="p-3 bg-light text-center">
                       <h3 className='fst-it fw-bold'> FORMULAIRE DE TRANSACTION</h3>
                    </div>
                    
                    {
                        activeStep === 0 ? <PersonalInfo
                                                oldValue = {formInfo.personalInfo}
                                                setFormInfo = {changeFormValue}
                                                globalInfo = {formInfo}
                                                errors = {formError}
                                            /> : null
                    }

                    {
                        activeStep === 1 ? <AmountSentFormInfo
                                                oldValue = {formInfo.amountSentInfo}
                                                setFormInfo = {changeFormValue}
                                                taxes={taxes}
                                                currencies={currencies}
                                                globalInfo = {formInfo}
                                                errors = {formError}
                                            /> : null
                    }

                    {
                        activeStep === 2 ? <AccountInfo
                                                oldValue = {formInfo.accountInfo}
                                                setFormInfo = {changeFormValue}
                                                globalInfo = {formInfo}
                                                paymentWays={paymentWays}
                                                errors = {formError}
                                            /> : null
                    }

                    

                    {
                        activeStep !== 2 ? <button onClick={goToNext} className="btn btn-primary p-2">
                                                <h6>SUIVANT</h6> 
                                            </button> : null
                    }

                    {
                        activeStep !== 0 ? <button onClick={goToPrev} className="btn btn-danger p-2 mt-2">
                                                <h6>RETOUR</h6> 
                                            </button> : null
                    }

                    {
                        activeStep === 2 ? <button onClick={registerTransaction} className="btn btn-success p-2 mt-2">
                                                <h6>VALIDER</h6> 
                                            </button>  : null
                    }
                </div>
            </div>

}