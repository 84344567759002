import DefaultFirebaseQuery from '../../vendor/bases/firebase.query';

export default class FeesQuery extends DefaultFirebaseQuery {
  constructor () {
    super ({
      name: 'params',
      path: 'params',
    });
  }

  async valid (id) {
    return await this.controller.active(id);
  }

  async cancel (id) {
    return await this.controller.disable(id);
  }

}
