import React from "react";
import { NavLink } from "react-router-dom";
import TransactionsService from "../../../../../external-communication/services/transactions.service";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function Transactions(props){
    return <TransactionsRender {...props}/>
}


class TransactionsRender extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            transactions : []
        }
        this.loadTransaction = this.loadTransaction.bind(this);
    }

    async componentDidMount(){
        await this.loadTransaction();
    }

    async loadTransaction(){
        if(this.props?.profileInformation?.id){
            const clientTransactionQuery = new TransactionsService().transactionByClient(this.props?.profileInformation?.id);
            if(clientTransactionQuery.success){
               await this.setState({
                transactions : (await clientTransactionQuery).data
               })
            }
        }
    }

    render(){
        return <div className="card">
                <div className="card-header">
                    <div className="row">
                        <div className='col-12 col-md-9 col-xl-9 col-lg-9'>
                            Historique des transaction(s)
                        </div>
                        <div className='col-12 col-md-3 col-xl-3 col-lg-3 d-flex justify-content-end'>
                            <NavLink  to="/new-transaction" className="btn btn-sm btn-primary" type="button">
                                Nouvelle transaction
                            </NavLink>
                        </div>
                    </div>
                    
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                                <tr>
                                    <th className="border-gray-200" scope="col">Transaction N.</th>
                                    <th className="border-gray-200" scope="col">Date</th>
                                    <th className="border-gray-200" scope="col">Moyen de paiement</th>
                                    <th className="border-gray-200" scope="col">Montant</th>
                                    <th className="border-gray-200" scope="col">Statut</th>
                                    <th className="border-gray-200" scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.transactions.length > 0 ?
                                    this.state.transactions.map(
                                        transaction => {
                                            return <tr key={transaction.id}>
                                                        <td>{transaction.id}</td>
                                                        <td>{moment(transaction.dateTransaction).format('DD/MM/YYYY HH:mm:ss')}</td>
                                                        <td>{transaction.paymentWay.name}</td>
                                                        <td>{transaction?.taxe.currency1?.name?.toUpperCase()+" "+transaction.total.toLocalString('fr-FR')}</td>
                                                        <td>
                                                            {
                                                                transaction.state === 0 ? 
                                                                    <span className="badge bg-light text-dark">En attente</span> : null

                                                            }
                                                            {
                                                                transaction.state === 1 ? 
                                                                    <span className="badge bg-success text-white">Validee</span> : null

                                                            }
                                                            {
                                                                transaction.state === 1 ? 
                                                                    <span className="badge bg-danger text-white">Annulee</span> : null

                                                            }
                                                        </td>     
                                                        <td>
                                                            <button title='detail' className="btn btn-sm btn-primary"> 
                                                                <i>
                                                                    <FontAwesomeIcon icon="eye"/>
                                                                </i>
                                                            </button>   
                                                        </td>
                                                    </tr>
                                        }
                                    )
                                     : 
                                    <tr>
                                        <td className="text-center" colSpan={6}>
                                            <span className="text-center">Aucune transaction effectuee :) </span>
                                        </td>
                                    </tr>
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    }
}